import type { ListPickerOption, ListPickerProps } from '../Types/listPicker';

import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

import { ReactComponent as CloseIcon } from './../../assets/icons/close.svg';

/**
 * ListPicker
 */
export function ListPicker({ onChange, id, options, className, placeholder }: ListPickerProps): JSX.Element {
  const [currentText, setCurrentText] = useState('');

  const selectOption: (selectedOption: string) => void = (selectedOption: string) => {
    onChange(selectedOption);
  };

  const handleFilterChange: (text: string) => void = (text: string) => {
    if (text.length > 0) {
      setCurrentText(text);
    } else {
      setCurrentText('');
    }
  };

  const resetFilter: () => void = () => {
    setCurrentText('');
  };

  let filteredOptions: ListPickerOption[] = options;
  if (currentText !== '') {
    filteredOptions = options.filter((option: ListPickerOption) =>
      option.label.toLowerCase().includes(currentText.toLocaleLowerCase()),
    );
  }

  const getList: () => JSX.Element[] = () => {
    const list: JSX.Element[] = [];
    let currentLetter: string = '';

    filteredOptions.forEach((option: ListPickerOption) => {
      const firstLetter: string = option.label.charAt(0);
      const needHeader: boolean = currentLetter !== firstLetter;

      if (needHeader) {
        list.push(
          (<li className='header-set' key={`header-${firstLetter}`}>
            {firstLetter}
          </li>),
        );
      }
      list.push(
        (<li key={option.id} onClick={() => selectOption(option.id)}>
          {option.label}
        </li>),
      );
      currentLetter = firstLetter;
    });

    return list;
  };

  const listClassName: string = currentText.length > 0 ? 'filter-on' : 'filter-off';

  return (
    <div id={id} className={`list-picker ${listClassName} ${className}`}>
      <Form.Group>
        <InputGroup>
          <Form.Control
            type='text'
            value={currentText}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterChange(e.target.value)}
            placeholder={placeholder}
            aria-describedby='inputGroupPrepend'
          />
          <InputGroup.Text
            id='inputGroupPrepend'
            className={currentText.length > 0 ? 'visible' : 'invisible'}
            onClick={resetFilter}>
            <CloseIcon />
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>
      <ul>
        {getList().map((value: JSX.Element) => value)}
      </ul>
    </div>
  );
}
