// tslint:disable: max-line-length
import mixpanel from 'mixpanel-browser';
import * as qs from 'query-string';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';

import { useCancelRegistrationMutation } from '../../generated/graphql';
import { FooterButton } from '../../Shared/Components';
import { getBrandSlugFromBrandCode } from '../../utils/brands';

import { RegisteredProps } from '../Types/registered';

import { QRCodeImage } from '.';
import { useGetActionLinks } from '../../Shared/Hooks/useGetActionLinks';

/**
 * Registered
 */
export function Registered({ memberState, brandCode, refetch }: RegisteredProps): JSX.Element {
  const [showCancelModal, setShowCancelModal] = useState(false);

  const query: qs.ParsedQuery = qs.parse(window.location.search);
  if (query.clientRegistrationStatus === 'success') {
    localStorage.setItem(memberState.memberId, 'success');
  }

  const [cancelRegistration] = useCancelRegistrationMutation();
  const { data: actionLinksData } = useGetActionLinks(undefined, memberState?.memberId);


  const wireLinkUrl: string | undefined
    = actionLinksData?.actionLinks?.wireLink ?? undefined;

  const bookAnAppointmentUrl: string | undefined
    = actionLinksData?.actionLinks?.bookAnAppointment ?? undefined;

  const register: () => void = () => {
    if (wireLinkUrl !== undefined) {
      mixpanel.identify(memberState.memberId);

      mixpanel.track('Wire registration');

      mixpanel.people.set({
        brandName: memberState?.brandName,
        storeCode: memberState?.locationCode,
      });

      window.location.assign(`${wireLinkUrl}`);
    }
  };

  const bookAnAppointment: () => void = () => {
    if (bookAnAppointmentUrl !== undefined) {
      mixpanel.identify(memberState.memberId);

      mixpanel.track('Book an appointment');

      mixpanel.people.set({
        brandName: memberState?.brandName,
        storeCode: memberState?.locationCode,
      });

      window.location.assign(`${bookAnAppointmentUrl}`);
    }
  };

  if (memberState.clientId !== null) {
    mixpanel.people.set({
      isClient: true,
    });
  }

  const canRegister: () => boolean = () =>
    wireLinkUrl !== undefined &&
    localStorage.getItem(memberState.memberId) === null &&
    memberState.clientId === null &&
    memberState.position > 0;

  const isBookingEnabled: () => boolean = () =>
    bookAnAppointmentUrl !== undefined &&
    memberState.position > 0;

  const handleCloseCancelModal: () => void = () => setShowCancelModal(false);
  const handleShowCancelModal: () => void = () => setShowCancelModal(true);

  const cancel: () => Promise<void> = async () => {
    handleCloseCancelModal();

    await cancelRegistration({
      variables: {
        memberId: memberState.memberId,
      },
    });

    mixpanel.identify(memberState.memberId);

    mixpanel.track('Client leave');
    mixpanel.disable(['Client leave'])

    mixpanel.people.set({
      brandName: memberState?.brandName,
      storeCode: memberState?.locationCode,
    });

    await refetch();
  };

  const { t } = useTranslation();
  const brandSlug: string = getBrandSlugFromBrandCode(brandCode);

  return (
    <div id='status-registered'>
      <div className='status-top'>
        <div className='status-description'>
          <p className='status-description-thankyou'>{t(
            'status.registered.description.part1',
            {
              storeName: memberState.storeName,
              firstname: memberState.firstname,
              lastname: memberState.lastname,
            }
          )}</p>
          <p className='status-description-position'>
            <span className='status-description-position-start'>{t('status.registered.description.part2')}</span> <span className='status-description-position-number'>{memberState.position === 0 ? t('status.registered.description.part3', '') : `#${memberState.position + 1}`}</span> <span className='status-description-position-end'>{t('status.registered.description.part4', { storeName: memberState.storeName })}</span>
          </p>
        </div>
        {
          memberState.position === 0
            ? <>
              <div className='status-next'>
                <p>
                  {t('status.registered.next')}
                </p>
              </div>
              <div className='status-qrcode'>
                <div className='status-qrcode-inner'>
                  <QRCodeImage url={JSON.stringify({ memberId: memberState.memberId })} />
                </div>
              </div>
            </>
            : <></>
        }
        {
          isBookingEnabled()
            ? <div className='status-client-booking'>
                <p>
                  <Trans
                    i18nKey={"status.registered.bookAnAppointment"}
                    components={{
                      bookingLink: <span className='status-booking-action' onClick={bookAnAppointment} />,
                    }}
                  />
                </p>
              </div>
            : <></>
        }
        <div className='status-cancellation'>
          <p>
            <Trans
              i18nKey={"status.registered.cancellation"}
              components={{
                cancelLink: <span className='status-cancellation-action' onClick={handleShowCancelModal} />,
              }}
            />
          </p>
        </div>
        {
          canRegister()
            ? <div className='status-client-registration'>
              <p>
                {t('status.registered.register.status')}
              </p>
            </div>
            : <></>
        }
      </div>
      <div className='status-bottom'>
        {
          canRegister()
            ? <div className="register-bottom">
              <FooterButton text={t('status.registered.register.button')} onClick={register} /></div>
            : <></>
        }
      </div>
      <Modal centered animation={false} show={showCancelModal} className={`brand-${brandSlug} custom-modal`} onHide={handleCloseCancelModal}>
        <Modal.Header>
          <Modal.Title>{t('status.registered.cancel.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('status.registered.cancel.body')}</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseCancelModal}>
            {t('status.registered.cancel.secondary')}
          </Button>
          <Button variant='primary' onClick={cancel}>
            {t('status.registered.cancel.primary')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

