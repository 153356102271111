import { QueryResult } from '@apollo/client';

import { GetTranslatedReasonsQuery, Locale, useGetTranslatedReasonsQuery } from '../../generated/graphql';

/**
 * useGetTranslatedReasons
 */
export function useGetTranslatedReasons(brandId: string, locale: Locale): QueryResult<GetTranslatedReasonsQuery> {
  return useGetTranslatedReasonsQuery({
    variables: { brandId, locale },
  });
}
