import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import mixpanel from 'mixpanel-browser';
import { Trans } from 'react-i18next';
import { Redirect, useHistory, useParams } from 'react-router';

import { GetLocationStateQuery, Locale } from '../../generated/graphql';
import { FooterButton, ListPicker, Loader } from '../../Shared/Components';
import { withBrand } from '../../Shared/Components/withBrand';
import { useGetLocationState } from '../../Shared/Hooks/useGetLocationState';
import { useQm } from '../../Shared/Hooks/useQm';
import { ListPickerOption } from '../../Shared/Types/listPicker';
import { getLanguageFromLocale, getLanguageLabelFromLocale } from '../../utils/languages';
import { useGetActionLinks } from '../../Shared/Hooks/useGetActionLinks';

/**
 * WelcomeContainer
 */
function WelcomeContainer(): JSX.Element {
  const history: any = useHistory();
  const { locationId } = useParams<{ locationId: string }>();
  const { data, loading } = useGetLocationState(locationId);
  const { data: actionLinksData } = useGetActionLinks(locationId);
  const { availableLocales, initLocales, isLanguagePickerOpen, setLanguage, showLanguagePicker } = useQm();
  const { t } = useTranslation('translation', { keyPrefix: 'welcome' });

  const locationState: GetLocationStateQuery['locationState'] | undefined = data?.locationState ?? undefined;

  const defaultOptions: ListPickerOption[] = availableLocales
    .map((locale: Locale) => (
      {
        id: getLanguageFromLocale(locale),
        label: getLanguageLabelFromLocale(locale),
      }
    ))
    .sort((a: ListPickerOption, b: ListPickerOption) => a.label.localeCompare(b.label));

  useEffect(
    () => {
      if (locationState !== undefined) {
        initLocales(
          locationState.defaultBrandLocales,
          locationState.defaultLanguageLocale,
          undefined,
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locationState],
  );

  if (loading) {
    return (
      <Loader full />
    );
  }

  if (locationState === undefined) {
    return (
      <Redirect to={{ pathname: '/error' }} />
    );
  }

  if (!locationState.queueEnabled) {
    return (
      <Redirect to={{ pathname: '/error', state: { error: 'queueDisabled', locationId } }} />
    );
  }

  const bookAnAppointmentUrl: string | undefined
    = actionLinksData?.actionLinks?.bookAnAppointment ?? undefined;

  const bookAnAppointment: () => void = () => {
    if (bookAnAppointmentUrl !== undefined) {
      mixpanel.track('Book an appointment');

      mixpanel.people.set({
        brandName: data?.locationState.brandName,
        storeCode: data?.locationState.locationCode,
      });

      window.location.assign(`${bookAnAppointmentUrl}`);
    }
  };

  const isBookingEnabled: () => boolean = () =>
    bookAnAppointmentUrl !== undefined &&
    (data?.locationState.memberCount ?? 0) > 0;

  const signup: () => void = () => {
    history.push(`/l/${locationId}/r`);
  };

  return (
    <div id='welcome'>
      {isLanguagePickerOpen
        ? <ListPicker
          id='language-picker'
          placeholder='Search language'
          onChange={setLanguage}
          options={defaultOptions}
        />
        :
        <>
          <div id='language-picker-menu'>
            <p onClick={showLanguagePicker}>
              <Trans i18nKey={`language`} />
              <i className='gg-chevron-down'></i>
            </p>
          </div>
          <div className='welcome-top'>
            <div className='welcome-queue-opened'>
              <p>{t('info', { count: locationState.memberCount, storeName: locationState.name })}</p>
            </div>
            {
              isBookingEnabled()
                ? <div className='welcome-client-booking'>
                    <p>
                      <Trans
                        i18nKey={"status.registered.bookAnAppointment"}
                        components={{
                          bookingLink: <span className='welcome-booking-action' onClick={bookAnAppointment} />,
                        }}
                      />
                    </p>
                  </div>
                : <></>
            }
            <div className='welcome-signUp'>
              <p>{t('signUp')}</p>
            </div>
          </div>
          <div className='welcome-bottom'>
            <FooterButton text={t('action')} onClick={signup} />
          </div>
        </>
      }
    </div>
  );
}

/**
 * Welcome
 */
// tslint:disable-next-line: variable-name
export const Welcome: any = withBrand(WelcomeContainer, 'welcome-page');
