import { default as QRCode } from 'qrcode.react';
import React from 'react';

/**
 * QRCodeImage
 */
export function QRCodeImage({ url }: { url: string}): JSX.Element {
  return (
    <QRCode renderAs='svg' value={url} />
  );
}
