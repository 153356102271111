import { TranslationType } from 'i18n/type';

const data: TranslationType = {
  language: 'Deutsch',
  registration: {
    signUp: 'Melden Sie sich für die Warteschlange an',
    registerForm: {
      firstname: 'Vorname',
      lastname: 'Nachname',
      partySize: 'Wie viele Personen sind Sie?',
      phoneNumber: 'Telefonnummer',
      smsNotice: 'SMS-Updates bezüglich Ihres Warteschlangenstatus werden an diese Telefonnummer gesendet. Es gelten die Standardtarife.',
      reason: 'Was ist der Zweck Ihres Besuchs?',
      otherReason: 'Wenn Sonstiges, bitte angeben',
      noOptions: "No options",
      privacy: {
        confirm: 'Mit der Aktivierung des Kontrollkästchens bestätigen Sie, dass die von Ihnen angegebene Telefonnummer Ihre eigene ist und Sie den Datenschutzrichtlinien zustimmen.',
        more: {
          title: 'Persönliche Daten',
          what: 'Wie verwenden wir Ihre persönlichen Daten?',
          details: {
            sl: 'YVES SAINT LAURENT SAS verarbeitet Ihre personenbezogenen Daten als Verantwortlicher, um: (i) die Warteschlangen am Eingang der Verkaufsstellen zu verwalten; und (ii) sofern Sie bereits ein Profil haben, Sie zu erkennen, bevor Sie die Verkaufsstelle betreten, um Ihren Einkauf zu optimieren. Sie haben verschiedene Rechte in Bezug auf Ihre personenbezogenen Daten. Für weitere Informationen zur Verarbeitung Ihrer personenbezogenen Daten (einschließlich der Ausübung Ihrer Rechte) klicken Sie bitte hier <privacyLink>{{link}}</privacyLink>.',
            balenciaga: '',
            gucci: "Guccio Gucci S.p.A. ('Gucci', 'wir') beabsichtigt in seiner Funktion als Datenverantwortlicher, Sie darüber zu informieren, dass die von Ihnen zur Verfügung gestellten Daten von uns verarbeitet werden, um die Warteschlangen am Eingang der Verkaufsstellen zu verwalten (i) und Sie, sofern Sie bereits ein Profil haben zu erkennen, bevor Sie den Verkaufspunkt betreten, um Ihren Empfang zu optimieren (ii). Die Verarbeitung Ihrer Daten erfolgt ausschließlich durch autorisiertes Personal gemäß dem Gesetz, um Ihnen den angeforderten Service zu bieten. Ihre persönlichen Daten werden nicht außerhalb des Landes, in dem sie ursprünglich erhoben wurden oder außerhalb des EWR übertragen, offengelegt oder anderweitig an Dritte weitergegeben. Sie können Ihre Rechte jederzeit gemäß den geltenden Datenschutzbestimmungen ausüben, indem Sie eine E-Mail an privacy@gucci.com senden. Detaillierte Informationen zu Ihren Rechten finden Sie unter folgendem Link (<privacyLink>{{link}}</privacyLink>).",
            amq: '',
            brioni: '',
            bv: '',
          },
        },
      },
      countryCode: 'internationale Vorwahl',
      signUp: 'Bestätigen',
      errors: {
        required: 'Erforderlich',
        minLength: 'Es müssen {{val, number}} Zeichen sein oder mehr',
        minNumberOfGuests: 'Es müssen mindestens {{val, number}}',
        maxNumberOfGuests: 'Es dürfen maximal {{val, number}}',
        invalidCountryCode: 'Ungültiges Land',
        invalidPhoneNumber: 'Ungültige Telefonnummer',
        privacy: 'Bitte bestätigen sie die AGB\'s',
      },
    },
  },
  shared: {
    loading: 'Lädt…',
  },
  status: {
    accepted: 'Sie wurden bereits vom Store angenommen.',
    canceled: {
      confirm: 'Sie wurden erfolgreich aus der Warteschlange entfernt.',
      thanks: 'Wir freuen uns darauf, Sie bald wiederzusehen.',
    },
    registered: {
      description: {
        part1: 'Vielen Dank. {{firstname}}!',
        part2: 'Sie sind nun',
        part3: 'der nächste',
        part4: 'in der Warteschlange für "{{storeName}}"',
      },
      next: 'Sie dürfen jetzt gerne in den Store eintreten. Bitte scannen sie den QR-Code am Eingang',
      cancellation: 'Haben Sie kein Interesse mehr, unseren Store zu besuchen? <cancelLink>Klicken Sie hier, um die Warteschlange zu verlassen</cancelLink>.',
      register: {
        status: 'Registrieren Sie sich, um bei jedem Einkauf ein personalisiertes Einkaufserlebnis zu erfahren.',
        button: 'Mein Profil registrieren',
      },
      cancel: {
        title: 'Warteschlange verlassen',
        body: 'Wenn Sie die Warteschlange jetzt verlassen, verlieren Sie ihren aktuellen Platz.',
        secondary: 'Bleiben',
        primary: 'Verlassen',
      },
      bookAnAppointment: 'Überspringen Sie die Warteschlange und <bookingLink>buchen Sie einen Termin</bookingLink>',
    },
  },
  errors: {
    global: 'Entschuldigen Sie, es ist ein Fehler aufgetreten.',
    queueDisabled: 'Die Warteschlange für {{brand}} {{storeName}} ist geschlossen',
  },
  welcome: {
    language: 'Sprache:',
    info: 'Willkommen. Derzeit befinden sich {{count}} Personen in der Warteschlange für {{storeName}} vor Ihnen.',
    signUp: 'Klicken Sie auf Anmelden, um sich sich in der Warteschlange einzureihen',
    action: 'Anmelden',
  },
};

export default data;
