import type { TranslationType } from 'i18n/type';

const data: TranslationType = {
  language: 'Japanese',
  registration: {
    signUp: '入店待ちの列に登録する',
    registerForm: {
      firstname: '名（ファーストネーム）',
      lastname: '姓（ラストネーム）',
      partySize: '入店される方は何名ですか？',
      phoneNumber: '電話番号',
      smsNotice: '入店待ちの列の最新状況がこの電話番号にSMS送信されます。標準料金が適用されます。',
      reason: 'ご来店いただいた目的をお聞かせください。',
      otherReason: 'その他の場合は、具体的にお答えください',
      noOptions: 'No options',
      privacy: {
        confirm: 'このボックスを選択すると、提供された電話番号があなたの番号であることを証明し、個人情報保護方針に同意されたことになります。',
        more: {
          title: '個人情報',
          what: 'どのような目的で個人情報を使用しますか',
          details:
          {
            sl: 'YVES SAINT LAURENT SAS は、管理者として、次の目的でお客様の個人データを処理します。 (ii) すでにプロファイルをお持ちの場合は、受信を最適化するために、POS にアクセスする前にお客様を認識します。お客様は、お客様の個人データに関するさまざまな権利から利益を得ることができます。個人データの処理に関する追加情報 (権利の行使方法を含む) については、ここ <privacyLink>{{link}}</privacyLink> をクリックしてください。',
            balenciaga: '',
            gucci: 'Guccio Gucci S.p.A.（以下、\'Gucci\'、\'私たち\'）は、個人情報取扱事業者として、ご提供いただいたデータを以下の目的で処理する予定です：（i）販売店の入口での待ち行列の管理、および（ii）既にプロフィールをお持ちの場合、販売店にアクセスする前にお客様を認識し、お客様の受付を最適化すること。個人情報の処理は法令に基づき許可されたスタッフによって行われ、要求されたサービスを提供するためにのみ行われます。お客様の個人情報は、元の収集国またはEEA（欧州経済領域）の外に転送されることはなく、第三者に開示または他の方法で通知されることはありません。個人情報保護法に従い、privacy@gucci.com にメールを送信することで、いつでもお客様の権利を行使できます。詳細な権利に関する情報は次のリンク（https://www.gucci.com/jp/ja/st/privacy-landing　(<privacyLink>{{link}}</privacyLink>)）からご確認いただけます。',
            amq: '',
            brioni: '',
            bv: '',
          },
        },
      },
      countryCode: '国コードの後',
      errors: {
        required: '必須',
        minLength: '{{val, number}}文字以上必要です',
        minNumberOfGuests: '少なくとも{{val, number}}必要です',
        maxNumberOfGuests: '{{val, number}}までです',
        invalidCountryCode: '無効な国コードです',
        invalidPhoneNumber: '無効な電話番号です',
        privacy: '規約への同意が必要です',
      },
      signUp: '確認',
    },

  },
  shared: {
    loading: '読み込み中・・・',
  },

  status: {
    accepted: '既にこの店舗で受付けされています。',
    canceled: {
      confirm: '正常に入店待ちの列から削除されました。',
      thanks: 'またのお越しをお待ちしております。',
    },
    registered: {
      description: {
        part1: 'ありがとうございます、お客様の前の方が入店可能となりました {{lastname}}。',
        part2: '',
        part3: '',
        part4: '',
      },
      next: 'お客様が入店可能となりました！店舗入口にあるQRコードを読み取ってください。',
      register: {
        status: 'ご登録いただくと、お買い物をされるときはいつでも特別な店舗体験をご用意いたします。',
        button: 'プロフィールを登録する',
      },
      cancellation: '当店へのご来店を中止しますか？<cancelLink>こちらをクリックして入店待ちの列を離れてください。</cancelLink>',
      cancel: {
        title: '入店待ちの列を離れる',
        body: 'いま入店待ちの列を離れると、順番を取り直すことになります。',
        secondary: '留まる',
        primary: '離れる',
      },
      bookAnAppointment: '列をスキップして<bookingLink>予約を入れる</bookingLink>',
    },
  },
  welcome: {
    language: '言語',
    info: '{{storeName}}の入店待ちの列にようこそ、現在、{{count}}名がお並びです',
    signUp: '下の「新規登録」をタップして、入店待ちの列にお並びください。',
    action: '新規登録',
  },
  errors: {
    global: '申し訳ございませんが、問題が発生しました。',
    queueDisabled: '{{brand}}の{{storeName}}の入店待ちの列は終了しました',
  },
};

export default data;
