import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router';

import { GetMemberStateQuery, MemberStatus } from '../../generated/graphql';
import { Loader } from '../../Shared/Components';
import { withBrand, WithBrandProps } from '../../Shared/Components/withBrand';
import { useGetMemberState } from '../../Shared/Hooks/useGetMemberState';
import { useQm } from '../../Shared/Hooks/useQm';

import { Accepted, Canceled, Registered } from '../Components';

/**
 * StatusContainer
 */
function StatusContainer({ brandCode }: WithBrandProps): JSX.Element {
  const [state, setState] = useState<NodeJS.Timeout>();
  const { memberId } = useParams<{ memberId: string }>();
  const { initLocales } = useQm();
  const { data, loading, refetch } = useGetMemberState(memberId);

  const memberState: GetMemberStateQuery['memberState'] | undefined = data?.memberState ?? undefined;

  useEffect(
    () => {
      if (state !== undefined) {
        clearInterval(state);
        setState(undefined);
      }

      if (memberState !== undefined && memberState.status === MemberStatus.Registered) {
        setState(setInterval(async () => refetch(), 15 * 1000));
      }

      if (memberState !== undefined) {
        initLocales(
          memberState.brandLanguagesLocale,
          memberState.locationLanguageLocale,
          memberState.languageLocale,
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [memberState],
  );

  if (loading) {
    return (
      <Loader full />
    );
  }

  if (memberState === undefined) {
    return (
      <Redirect to={{ pathname: '/error' }} />
    );
  }

  return (
    <>
      {
        memberState.status === MemberStatus.Registered
          ? <Registered
              memberState={ memberState }
              refetch={ refetch }
              brandCode={ brandCode }
            ></Registered>
          : <></>
      }
      {
        memberState.status === MemberStatus.Canceled || memberState.status === MemberStatus.Removed
          ? <Canceled memberState={memberState}></Canceled>
          : <></>
      }
      {
        memberState.status === MemberStatus.Accepted
          ? <Accepted></Accepted>
          : <></>
      }
    </>
  );
}

/**
 * Status
 */
// tslint:disable-next-line: variable-name
export const Status: any = withBrand(StatusContainer, 'status');
