import type { TranslationType } from 'i18n/type';

const data: TranslationType = {
  language: 'Chinese (Simplified)',
  registration: {
    signUp: '报名参与排队',
    registerForm: {
      firstname: '名字',
      lastname: '姓氏',
      partySize: '你们一行有多少人?',
      phoneNumber: '电话号码',
      smsNotice: '关于您的排队状态的短信将发送到这个电话号码。标准费率',
      reason: '您来我们店是想买点什么呢？',
      otherReason: '如有其他，请注明',
      noOptions: 'No options',
      privacy: {
        confirm: '勾选此框以证明您所提供的号码是您自己的，并且您同意本隐私政策。',
        more: {
          title: '个人资料',
          what: '我们会如何使用您的个人资料？',
          details: {
            sl: 'YVES SAINT LAURENT SAS 作为管理员会处理您的个人资料并用于： (i) 在门店入口处管理排队顺序，及(ii) 如果您已经留有资料，在您访问门店前识别您，并优化您的接待。您可享有有关个人资料的各项权利。有关处理您的个人数据的其他信息(包括您如何行使您的权利)，请点击这里<privacyLink>{{link}}</privacyLink>。',
            balenciaga: '',
            gucci: '古驰 Gucci S.p.A.（以下简称 \'古驰\'、\'我们\'），作为数据控制者，打算通知您，我们将处理您提供给我们的数据，以便：（i）管理销售点入口处的排队；和（ii）如果您已经拥有个人资料，在进入销售点前识别您，以优化您的接待。您的数据将由依法授权的工作人员进行处理，仅用于提供您所请求的服务。您的个人数据不会被转移至原始收集地或欧洲经济区（EEA）之外的国家，也不会被披露或以其他方式传递给任何第三方。您可以随时根据适用的隐私法行使您的权利，发送电子邮件至 privacy@gucci.com。有关您的权利的更多详细信息可在以下链接找到 (<privacyLink>{{link}}</privacyLink>)。',
            amq: '',
            brioni: '',
            bv: '',
          },
        },
      },
      countryCode: '国际前缀',
      signUp: '确认',
      errors: {
        required: '必填',
        minLength: '必须是 {{val, number}} 或更多位数字',
        minNumberOfGuests: '至少 {{val, number}}',
        maxNumberOfGuests: '至多 {{val, number}}',
        invalidCountryCode: '无效的国家代码',
        invalidPhoneNumber: '无效的电话号码',
        privacy: '您必须同意这些条款',

      },
    },
  },
  shared: {
    loading: '加载中……',
  },
  status: {
    accepted: '您已经可以进入门店了。',
    canceled: {
      confirm: '您已经成功的离开排队了。',
      thanks: '期待您再次光临。',
    },
    registered: {
      description: {
        part1: '谢谢您，您就是下一位了 {{firstname}}!',
        part2: '',
        part3: '',
        part4: '',
      },
      next: '现在该您进入门店了！请在店铺门口扫描二维码',
      cancellation: '不再对我们的店铺感兴趣了？<cancelLink>点击这里离开排队</cancelLink>',
      register: {
        status: '注册即可在每次在我们店里购物时获得个性化的购物体验。',
        button: '填写我的个人资料',
      },
      cancel: {
        title: '离开排队',
        body: '如果您现在离开排队，您将不能以同样的位置重新参与排队。',
        secondary: '留下',
        primary: '离开',
      },
      bookAnAppointment: '为了避免排队时间较长，<bookingLink>请按此处提前预约</bookingLink>',
    },
  },
  errors: {
    global: '对不起，出了点问题。',
    queueDisabled: '{{brand}} {{storeName}} 的排队关闭了',
  },
  welcome: {
    language: '语言',
    info: '欢迎光临，{{storeName}} 还有 {{count}} 人等候',
    signUp: '点击下面的注册按钮加入排队',
    action: '注册',
  },
};

export default data;
