import React, { ReactNode } from 'react';

/**
 * LinkTextProps
 */
interface LinkTextProps {
  to?: string;
  title?: string;
  newPage?: boolean;
  children?: ReactNode;
}

/**
 * LinkText
 */
export function LinkText({ to = '#', title, newPage = false, children }: LinkTextProps): JSX.Element {
  return (
    <a
      href={to}
      target={newPage ? '_blank' : undefined}
      rel={newPage ? 'noopener noreferrer' : undefined}
      title={title}
    >
      {children}
    </a>
  );
}
