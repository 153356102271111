import type { TranslationType } from 'i18n/type';

const data: TranslationType = {
  language: 'Chinese (Traditional)',
  registration: {
    signUp: '報名參與排隊',
    registerForm: {
      firstname: '名',
      lastname: '姓',
      partySize: '您們一共有多少人？',
      phoneNumber: '電話號碼',
      smsNotice: '排隊狀態的簡訊更新將發送到此電話號碼。適用標準費率。',
      reason: '您蒞臨店舖的目的是什麼呢？',
      otherReason: '如有其他，請說明',
      noOptions: 'No options',
      privacy: {
        confirm: '選擇此框，即表示您提供的電話號碼是您自己的號碼並且您同意私隱政策。',
        more: {
          title: '個人資訊',
          what: '我們如何使用您的個人資訊',
          details: {
            sl: 'YVES SAINT LAURENT SAS 作為控制者處理您的個人數據，以便： (i) 管理銷售點入口處的排隊； (ii) 如果您已經擁有個人資料，在您拜訪銷售點之前辨別您的顧客資料，來加強對您的接待。 您受益於有關您的個人資訊的各種權利。 有關處理您的個人資訊（包括如何行使您的權利）的更多信息，請點擊此處<privacyLink>{{link}}</privacyLink>。',
            balenciaga: '',
            gucci: '古馳 Gucci S.p.A.（以下簡稱 \'古馳\'、\'我們\'），身為數據控制者，打算通知您，我們將處理您提供給我們的數據，以便：（i）管理銷售點入口處的排隊；以及（ii）如果您已經擁有個人檔案，在進入銷售點前識別您，以優化您的接待。您的數據將由依法授權的工作人員進行處理，僅用於提供您所請求的服務。您的個人數據不會轉移到原始收集地或歐洲經濟區（EEA）之外的國家，也不會被披露或以其他方式傳遞給任何第三方。您可以隨時根據適用的隱私法行使您的權利，發送電子郵件至 privacy@gucci.com。有關您的權利的更多詳細信息可在以下連結找到 (<privacyLink>{{link}}</privacyLink>)。',
            amq: '',
            brioni: '',
            bv: '',
          },
        },
      },
      countryCode: '國際前綴',
      signUp: '確認',
      errors: {
        required: '必填',
        minLength: '至少為 {{val, number}} 個字元',
        minNumberOfGuests: '至少為 {{val, number}}',
        maxNumberOfGuests: '最多為 {{val, number}}',
        invalidCountryCode: '國家代碼無效',
        invalidPhoneNumber: '電話號碼無效',
        privacy: '您必須同意這些條款',
      },
    },
  },
  shared: {
    loading: '加載中……',
  },
  status: {
    accepted: '您已經可以進入店舖了。',
    canceled: {
      confirm: '您已成功從隊列中移除',
      thanks: '希望您再次光臨。',
    },
    registered: {
      description: {
        part1: '謝謝，您目前排在隊列最前面 {{firstname}}!',
        part2: '',
        part3: '',
        part4: '',
      },
      next: '現在輪到您進入店舖了！請在店門口掃描QR Code',
      cancellation: '不想進入我們的店舖了嗎？<cancelLink>點擊此處結束排隊。</cancelLink>',
      register: {
        status: '註冊後，您每次蒞臨本店選購時都可獲得個人化購物體驗。',
        button: '註冊我的個人資料',
      },
      cancel: {
        title: '結束排隊',
        body: '如果您現在結束排隊，您將失去現有順位。',
        secondary: '留下',
        primary: '離開',
      },
      bookAnAppointment: '為了避免排隊時間較長， <bookingLink>請按此處提前預約</bookingLink>',
    },
  },
  errors: {
    global: '抱歉，出了一些問題。',
    queueDisabled: '{{brand}} {{storeName}} 的隊列已關閉',
  },
  welcome: {
    language: '語言',
    info: '歡迎您，前方有 {{count}} 人在 {{storeName}} 排隊等候',
    signUp: '點擊下方註冊，加入排隊',
    action: '註冊',
  },
};

export default data;
