import type { TranslationType } from 'i18n/type';

const data: TranslationType = {
  language: 'Arabic',
  registration: {
    signUp: 'قم بالتسجيل في قائمة الانتظار',
    registerForm: {
      firstname: 'الاسم الأول',
      lastname: 'اللقب',
      partySize: 'كم عدد الأشخاص ؟',
      phoneNumber: 'رقم الهاتف',
      smsNotice:
        'سيتم إرسال رسائل قصيرة المتعلقة بحالة قائمة الانتظار إلى رقم الهاتف هذا.\n تطبق التعرفة المحلية.',
      reason: 'ما هو الغرض من زيارتك؟',
      otherReason: 'فضلا حدد إن كنت اخترت غير ذلك',
      noOptions: 'No options',
      privacy: {
        confirm:
          'أنقر هذا المربع للتصديق على أن الرقم الذي قدمته هو رقمك الخاص وأنك توافق على سياسة الخصوصية.',
        more: {
          title: 'بيانات شخصية',
          what: 'كيف نستخدم بياناتك الشخصية',
          details: {
            sl: '',
            balenciaga: '',
            gucci: 'تعتزم Guccio Gucci S.p.A. (\'Gucci\', \'نحن\') بصفتها متحكمة في البيانات أن تعلمك بأنها ستقوم بمعالجة البيانات التي قدمتها لنا بهدف: (i) إدارة الانتظار عند مداخل نقاط البيع؛ و (ii) التعرف عليك، في حال كان لديك بالفعل ملف شخصي، قبل دخولك إلى نقطة البيع، لتحسين استقبالك. ستتم معالجة بياناتك من قبل موظفين مرخصين وفقًا للقانون، فقط بهدف تقديم الخدمة المطلوبة لك. لن يتم نقل بياناتك الشخصية خارج البلد الذي تم جمعها فيه أصلاً أو خارج منطقة الاتحاد الأوروبي، ولن يتم الكشف عنها أو نقلها إلى أي أطراف ثالثة بطريقة أخرى. يمكنك ممارسة حقوقك في أي وقت وفقًا للتشريعات المعمول بها في مجال الخصوصية من خلال إرسال بريد إلكتروني إلى privacy@gucci.com. المزيد من المعلومات التفصيلية حول حقوقك متاحة على الرابط التالي (<privacyLink>{{link}}</privacyLink>).',
            amq: '',
            brioni: '',
            bv: '',
          },
        },
      },
      countryCode: 'رمز البلد',
      signUp: 'تأكيد',
      errors: {
        required: 'مطلوب',
        minLength: 'يجب أن يكون عدد الأحرف {{val number}} أو أكثر',
        minNumberOfGuests: 'يجب أن يكون على الأقل {{val number}}',
        maxNumberOfGuests: 'يجب ألا يزيد عن {{val number}}',
        invalidCountryCode: 'رمز الدولة غير صحيح',
        invalidPhoneNumber: 'رقم الهاتف غير صحيح',
        privacy: 'يجب أن توافق على الشروط',
      },
    },
  },
  shared: {
    loading: 'جار التحميل...',
  },
  status: {
    accepted: 'لقد تم قبولك في المتجر.',
    canceled: {
      confirm: 'لقد تمت إزالتك بنجاح من قائمة الانتظار.',
      thanks: ' سنكون سعداء لرؤيتك في المرة القادمة.',
    },
    registered: {
      description: {
        part1: 'شكرا لك أنت التالي في قائمة الانتظار ل {{firstname}}!',
        part2: '',
        part3: '',
        part4: '',
      },
      next: 'حان دورك الآن لدخول المتجر! يرجى مسح رمز الاستجابة السريعة عند مدخل المتجر',
      cancellation:
        '<cancelLink>لم تعد مهتمًا بزيارة متجرنا؟\n انقر هنا لمغادرة قائمة الانتظار.</cancelLink>',
      register: {
        status: 'سجل لتحصل على تجربة شخصية داخل المتجر في كل مرة تتسوق معنا.',
        button: 'تسجيل ملف التعريف الخاص بي',
      },
      cancel: {
        title: 'ترك قائمة الانتظار',
        body: 'إذا تركت قائمة الانتظار الآن فستفقد مكانك.',
        secondary: 'انتظر',
        primary: 'غادر',
      },
      bookAnAppointment: 'تخطى قائمة الانتظار و <bookingLink>إحجز موعد</bookingLink>',
    },
  },
  errors: {
    global: 'عذرا، هناك خطأ ما.',
    queueDisabled: 'تم إغلاق قائمة انتظار {{brand}} {{storeName}}',
  },
  welcome: {
    language: 'اللغة',
    info: 'مرحبًا ، هناك {{count}} أشخاص في قائمة انتظار {{storeName}}',
    signUp: 'اضغط على تسجيل أدناه للانضمام إلى قائمة الانتظار',
    action: 'اشتراك',
  },
};

export default data;
