import { QueryResult } from '@apollo/client';

import { GetMemberStateQuery, useGetMemberStateQuery } from '../../generated/graphql';

/**
 * useGetMemberState
 */
export function useGetMemberState(memberId: string): QueryResult<GetMemberStateQuery> {
  return useGetMemberStateQuery({
    variables: { memberId },
  });
}
