import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Country, getCountries, getCountryCallingCode } from 'react-phone-number-input';
import Select from 'react-select'

import { CountryOption, CountrySelectProps } from '../Types/countrySelect';

interface Option { value: Country, label: string }


const formatCountryOption = (country: Country) => (
  {
    value: country,
    label: `+${String(getCountryCallingCode(country))} (${country})`,
  }
)

export function CountrySelect({ onChange, country }: CountrySelectProps): JSX.Element {
  const { t } = useTranslation();

  const countries = getCountries()
  const options: Option[] = countries.map(formatCountryOption);
  const handleChange: (selected: Option) => void = (selected: Option) => {
    if (selected) {
      onChange(selected.value);
    } else {
      onChange('');
    }
  };

  const navigatorCountryOption: Option | undefined
    = options.find((option: CountryOption) => option.value === country.toLocaleUpperCase())

  useEffect(() => {
    if (navigatorCountryOption !== undefined) {
      handleChange(navigatorCountryOption)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  return (
    <Select defaultValue={navigatorCountryOption}
      options={options}
      onChange={handleChange as any}
      id={'country-code-select'}
      components={{ DropdownIndicator: null }}
      classNames={{
        menu: () => 'input-select-menu',
        menuList: () => 'input-select-menu-list',
        option: ({ isSelected, isFocused }) => `input-select-option ${isSelected ? 'input-select-option--is-selected' : ''} ${isFocused ? 'input-select-option--is-focused' : ''}`,
        control: () => 'input-select-control',
        valueContainer: () => 'input-select-value-container',
        input: () => 'input-select-input',
      }}
      noOptionsMessage={() => t('registration.registerForm.noOptions')}
      placeholder={t('registration.registerForm.countryCode')}
    />
  );
}
