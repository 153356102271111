import { createContext } from 'react';

import { Config } from '../Types/config';

/**
 * configContext
 */
export const configContext: React.Context<Config> = createContext<Config>({
  /**
   * REACT_APP_DEBUG
   */
  REACT_APP_DEBUG: false,

  /**
   * REACT_APP_API_URI
   */
  REACT_APP_API_URI: '',

  /**
   * REACT_APP_MIXPANEL_TOKEN
   */
  REACT_APP_MIXPANEL_TOKEN: '',
});
