import { BrandCode } from '@kering/types-shared';
import React, { ReactNode } from 'react';

import { getBrandSlugFromBrandCode } from '../../utils/brands';

/**
 * LayoutProps
 */
interface LayoutProps {
  id: string;
  fullpage?: boolean;
  children?: ReactNode;
  brandCode?: BrandCode;
}

/**
 * PageContainer
 */
export function Layout({id, fullpage = false, children, brandCode}: LayoutProps): JSX.Element {
  const brandSlug: string = getBrandSlugFromBrandCode(brandCode);
  const logosPath: string = `/public/images/logos/`;

  return (
    <div id={id} className={`container layout brand-${brandSlug}`}>
      {brandCode !== undefined
        ? <div className='header'>
            <h1><img src={`${logosPath}logo-${brandSlug}.svg`} alt={brandSlug} /></h1>
          </div>
        : <></>
      }
      {fullpage
        ?
          <div className='fullpage'>
            {children}
          </div>
        :
          <>{children}</>
      }
    </div>
  );
}
