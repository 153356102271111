import { BrandCode } from '@kering/types-shared';

/**
 * getBrandSlugFromBrandCode
 */
const getBrandSlugFromBrandCode:
  (brandCode: BrandCode | undefined) => string = (brandCode: BrandCode | undefined) => {
    switch (brandCode) {
      case BrandCode.GUCCI:
        return 'gucci';
      case BrandCode.BAL:
        return 'balenciaga';
      case BrandCode.BV:
        return 'bv';
      case BrandCode.YSL:
        return 'sl';
      case BrandCode.AMQ:
        return 'amq';
      case BrandCode.BR:
        return 'brioni';
      default:
        return 'group';
    }
  };

/**
 * getBrandFromHostname
 */
const getBrandFromHostname: (hostname: string) => BrandCode = (hostname: string) => {
  // TODO: Add prod url or move in a config file
  switch (hostname) {
    case 'gucci.qa.qm.dev4apps.com':
    case 'gucci.qm.keringapps.com':
      return BrandCode.GUCCI;
    case 'localhost':
    case 'bal.qa.qm.dev4apps.com':
    case 'queue.balenciaga.com':
      return BrandCode.BAL;
    case 'bv.qa.qm.dev4apps.com':
    case 'queue.bottegaveneta.com':
      return BrandCode.BV;
    case 'ysl.qa.qm.dev4apps.com':
    case 'queue.ysl.com':
      return BrandCode.YSL;
    case 'amq.qa.qm.dev4apps.com':
      return BrandCode.AMQ;
    case 'brioni.qa.qm.dev4apps.com':
      return BrandCode.BR;
    default:
      return BrandCode.GUCCI;
  }
};

/**
 * getPrivacyPolicyLinkFromBrandCode
 */
const getPrivacyPolicyLinkFromBrandCode:
  (brandCode: BrandCode | undefined) => string = (brandCode: BrandCode | undefined) => {
    switch (brandCode) {
      case BrandCode.YSL:
        return 'https://www.ysl.com/legal-pages/privacy-policy/privacy-policy.html';
      case BrandCode.BAL:
        return 'https://www.balenciaga.com/legal-pages/privacy-policy/privacy-policy.html';
      case BrandCode.BV:
        return 'https://www.bottegaveneta.com/en-ie/legal-pages/privacy-policy/privacy-policy.html';
      case BrandCode.GUCCI:
        return 'https://www.gucci.com/fr/fr/st/privacy-landing';
      default:
        return '#PrivacyPolicy';
    }
  };

export {
  getBrandSlugFromBrandCode,
  getBrandFromHostname,
  getPrivacyPolicyLinkFromBrandCode,
};
