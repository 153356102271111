import { TranslationType } from 'i18n/type';

const data: TranslationType = {
  language: 'Français',
  registration: {
    signUp: 'Réservez votre place dans la file d\'attente',
    registerForm: {
      firstname: 'Prénom',
      lastname: 'Nom',
      partySize: 'Merci d’indiquer le nombre de personnes lors de votre visite',
      phoneNumber: 'Numéro de téléphone ',
      smsNotice: 'Votre avancement dans la file d\'attente vous sera envoyé par SMS. Les frais habituels de votre opérateur téléphonique s\'appliquent.',
      reason: 'Quel est l’objet de votre visite ?',
      otherReason: 'Si autre, merci de préciser',
      noOptions: "Pas d'options",
      privacy: {
        confirm: 'Cochez cette case pour confirmer qu\'il sagit bien de votre numéro de téléphone et que vous acceptez notre <privacyLink>politique de protection des données</privacyLink>.',
        more: {
          title: 'Données personnelles',
          what: 'Que faisons-nous avec vos données personnelles ?',
          details: {
            sl: 'YVES SAINT LAURENT SAS traite vos données, en tant que responsable du traitement, pour : (i) gérer les files d’attente à l’entrée des points de vente ; et (ii) si vous disposez d’un profil, vous reconnaître, avant que vous n’accédiez au point de vente, afin d’optimiser votre accueil. Vous disposez de différents droits concernant la protection de vos données personnelles. Pour de plus amples informations quant au traitement de vos données personnelles (y compris l’exercice de vos droits), veuillez cliquer ici <privacyLink>{{link}}</privacyLink>.',
            balenciaga: '',
            gucci: "Guccio Gucci S.p.A. ('Gucci', 'nous'), en sa qualité de responsable du traitement des données, a l'intention de vous informer qu'elle traitera les données que vous nous avez fournies dans le but de : (i) gérer la file d'attente à l'entrée des points de vente ; et (ii) vous reconnaître, si vous avez déjà un profil, avant d'accéder au point de vente, afin d'optimiser votre accueil. Le traitement de vos données sera effectué par du personnel autorisé conformément à la loi, uniquement dans le but de vous fournir le service demandé. Vos données personnelles ne seront pas transférées en dehors du pays où elles ont été initialement collectées ou de l'EEE, ni divulguées ni communiquées à des tiers. Vous pouvez exercer vos droits à tout moment conformément à la législation applicable en matière de confidentialité, en envoyant un e-mail à privacy@gucci.com. Des informations plus détaillées sur vos droits sont disponibles sur le lien suivant (<privacyLink>{{link}}</privacyLink>).",
            amq: '',
            brioni: '',
            bv: '',
          },
        },
      },
      countryCode: 'préfixe international',
      signUp: 'Rejoindre la file',
      errors: {
        required: 'Champs obligatoire',
        minLength: 'Saisir un minimum de {{val, number}} caractères',
        minNumberOfGuests: 'Saisir un nombre égal ou supérieur à {{val, number}}',
        maxNumberOfGuests: 'Vous ne pouvez être plus de {{val, number}}',
        invalidCountryCode: 'Indicatif pays invalide',
        invalidPhoneNumber: 'Numéro de téléphone invalide',
        privacy: 'Veuillez accepter les conditions d\'utilisation',
      },
    },
  },
  shared: {
    loading: 'Chargement...',
  },
  status: {
    accepted: 'Vous avez déjà été accepté(e) dans la boutique.',
    canceled: {
      confirm: 'Nous vous confirmons que vous ne faites désormais plus partie de la file d\'attente.',
      thanks: 'Nous nous réjouissons de vous revoir prochainement.',
    },
    registered: {
      description: {
        part1: 'Merci {{firstname}}!',
        part2: 'Vous êtes désormais',
        part3: 'la prochaine personne',
        part4: 'dans la file d\'attente de "{{storeName}}"',
      },
      next: 'Vous pouvez vous présenter dès à présent à l\'entrée de la boutique. Merci de scanner le QR code à l\'entrée de la boutique',
      cancellation: 'Vous ne souhaitez plus vous rendre dans notre boutique ? <cancelLink>Cliquez ici si vous souhaitez quitter la file d\'attente.</cancelLink>',
      register: {
        status: 'Créez votre profil pour bénéficier d\'une expérience personnalisée en boutique.',
        button: 'Créer un profil',
      },
      cancel: {
        title: 'Quitter la file d\'attente',
        body: 'Si vous quittez la file, vous perdrez votre place dans la file d\'attente.',
        secondary: 'Rester',
        primary: 'Quitter',
      },
      bookAnAppointment: '<bookingLink>Prendre un rendez-vous</bookingLink> et quitter la queue',
    },
  },
  errors: {
    global: 'Nous vous prions de nous excuser, une erreur s\'est produite.',
    queueDisabled: 'Il n\'est actuellement pas possible de rejoindre la file d\'attente de la boutique de {{brand}} {{storeName}}',
  },
  welcome: {
    language: 'Langue :',
    info_one: 'Bienvenue, il y a actuellement {{count}} personne dans la file d\'attente de la boutique de {{storeName}}',
    info: 'Bienvenue, il y a actuellement {{count}} personnes dans la file d\'attente de la boutique de {{storeName}}',
    signUp: 'Cliquez sur "Rejoindre la file d\'attente" pour vous enregistrer',
    action: 'Rejoindre la file d\'attente',
  },
};

export default data;
