import type { TranslationType } from 'i18n/type';

const data: TranslationType = {
  language: 'Dutch',
  registration: {
    signUp: 'Meld je aan voor de wachtrij',
    registerForm: {
      firstname: 'Voornaam',
      lastname: 'Achternaam',
      partySize: 'Hoeveel mensen zitten er in uw partij?',
      phoneNumber: 'Telefoonnummer',
      smsNotice: 'Sms-updates met betrekking tot uw wachtrijstatus worden naar dit telefoonnummer verzonden. Standaard tarieven zijn van toepassing.',
      reason: 'Wat is het doel van uw bezoek?',
      otherReason: 'Indien anders, gelieve te specificeren',
      noOptions: 'No options',
      privacy: {
        confirm: 'Schakel dit vakje in om te bevestigen dat het nummer dat u hebt opgegeven uw eigen nummer is en dat u akkoord gaat met het <privacyLink>privacybeleid</privacyLink>.',
        more: {
          title: 'Persoonlijke gegevens',
          what: 'Hoe gebruiken wij uw persoonlijke gegevens?',
          details: {
            sl: '',
            balenciaga: '',
            gucci: 'Guccio Gucci S.p.A. (\'Gucci\', \'wij\', \'ons\'), in haar hoedanigheid van gegevensbeheerder, wil u informeren dat zij de gegevens die u aan ons heeft verstrekt, zal verwerken om: (i) de wachtrijen bij de ingang van de verkooppunten te beheren; en (ii) u, indien u al een profiel heeft, te herkennen voordat u het verkooppunt betreedt, om uw ontvangst te optimaliseren. De verwerking van uw gegevens zal worden uitgevoerd door geautoriseerd personeel in overeenstemming met de wet, uitsluitend om u de gevraagde service te bieden. Uw persoonlijke gegevens worden niet overgedragen buiten het land waar ze oorspronkelijk zijn verzameld of de EER, openbaar gemaakt of anderszins gedeeld met derden. U kunt te allen tijde uw rechten uitoefenen in overeenstemming met de toepasselijke privacywetgeving door een e-mail te sturen naar privacy@gucci.com. Gedetailleerde informatie over uw rechten is beschikbaar op de volgende link (<privacyLink>{{link}}</privacyLink>).',
            amq: '',
            brioni: '',
            bv: '',
          },
        },
      },
      signUp: 'Bevestigen',
      countryCode: 'Landnummer',
      errors: {
        required: 'Vereist',
        minLength: 'Moet {{val number}} tekens of meer zijn',
        minNumberOfGuests: 'Moet ten minste {{val number}} zijn',
        maxNumberOfGuests: 'Moet maximaal {{val number}} zijn',
        invalidCountryCode: 'Ongeldige landcode',
        invalidPhoneNumber: 'Ongeldig telefoonnummer',
        privacy: 'U moet akkoord gaan met de voorwaarden',
      },
    },
  },
  shared: {
    loading: 'Laden...',
  },
  status: {
    accepted: 'Je bent al toegelaten tot de winkel.',
    canceled: {
      confirm: 'U bent met succes uit de wachtrij verwijderd.',
      thanks: 'zien je graag de volgende keer.',
    },
    registered: {
      description: {
        part1: 'Bedankt. {{firstname}}!',
        part2: 'U bent',
        part3: 'nu de volgende',
        part4: 'in de wachtrij voor {{storeName}}',
      },
      next: 'Nu is het jouw beurt om de winkel binnen te gaan! Scan de QR-code bij de ingang van de winkel',
      cancellation: 'Geen interesse meer om onze winkel te bezoeken? <cancelLink>Klik hier om de wachtrij te verlaten</cancelLink>.',
      register: {
        status: 'Registreer om een gepersonaliseerde in-store ervaring te ontvangen elke keer dat u bij ons winkelt.',
        button: 'Registreer mijn profiel',
      },
      cancel: {
        title: 'verlaten',
        body: 'Als je nu de wachtrij verlaat, verlies je je plaats.',
        secondary: 'Blijven',
        primary: 'Verlaten',
      },
      bookAnAppointment: '<bookingLink>Reserveer een afspraak</bookingLink> en sta niet in de rij',
    },
  },
  errors: {
    global: 'Sorry dat er iets mis is gegaan.',
    queueDisabled: 'De wachtrij voor {{brand}} {{storeName}} is gesloten',
  },
  welcome: {
    language: 'Taal',
    info: 'Welkom, er staan {{count}} mensen in de wachtrij voor {{storeName}}',
    signUp: 'Tik hieronder op aanmelden om aan te sluiten bij de wachtrij',
    action: 'Inschrijven',
  },
};

export default data;
