
import type { AppVersion, Config } from './Config/Types/config';

import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, from } from '@apollo/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './app/app';
import { configContext } from './Config/Components/context';
import * as i18n from './i18n/config';
import './index.css';
import { reportWebVitals } from './reportWebVitals';
import { QmProvider } from './Shared/Hooks/useQm';

fetch('/public/env.json')
  .then(async (res: Response) => res.json())
  .then(
    ({
      REACT_APP_DEBUG,
      REACT_APP_API_URI,
      REACT_APP_MIXPANEL_TOKEN,
    }: Config) => {
      fetch('/public/version.json')
        .then(async (res: Response) => res.json())
        .then(
          async ({ version }: AppVersion) => {
            await i18n.init();
            mixpanel.init(REACT_APP_MIXPANEL_TOKEN);

            localStorage.setItem('appVersion', version);
            const client = new ApolloClient<unknown>({
              link: from([new HttpLink({ uri: REACT_APP_API_URI })]),
              headers: {
                'X-LUCE-AppId': 'QM_FRONT_WEBAPP',
                'X-LUCE-Version': localStorage.getItem('appVersion') ?? '',
              },
              cache: new InMemoryCache(),
            });

            ReactDOM.render(
              <QmProvider>
                <ApolloProvider client={client}>
                  <configContext.Provider value={
                    {
                      REACT_APP_DEBUG,
                      REACT_APP_API_URI,
                      REACT_APP_MIXPANEL_TOKEN,
                    }
                  }>
                    <App />
                  </configContext.Provider>
                </ApolloProvider>
              </QmProvider>,
              document.getElementById('root'),
            );
          }).catch(
          (e: Error) => console.error(e),
        );
    }).catch(
    (e: Error) => console.error(e),
  );

/* If you want to start measuring performance in your app, pass a function
   to log results (for example: reportWebVitals(console.log))
   or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals */
reportWebVitals();
