// tslint:disable
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** DateTime expected format to be YYYY-MM-DDTHH:mm:sss.ZZZ */
  DateTime: any;
};

export type AcceptMemberPayload = Location | MaxCapacityReachedError;

export type ActionLinks = {
  __typename?: 'ActionLinks';
  bookAnAppointment?: Maybe<Scalars['String']>;
  wireLink?: Maybe<Scalars['String']>;
};

export type Brand = {
  __typename?: 'Brand';
  code: BrandCode;
  id: Scalars['String'];
  languagesLocale: Array<Locale>;
  name: Scalars['String'];
  translatedReasons: Array<TranslatedReason>;
};

export enum BrandCode {
  Amq = 'AMQ',
  Bal = 'BAL',
  Br = 'BR',
  Bv = 'BV',
  Gucci = 'GUCCI',
  Mcq = 'MCQ',
  Smc = 'SMC',
  Ysl = 'YSL'
}

export type CountGroupBy = {
  __typename?: 'CountGroupBy';
  count: Scalars['Int'];
  groupField: Scalars['String'];
  groupName?: Maybe<Scalars['String']>;
  subGroups?: Maybe<Array<CountGroupBy>>;
};

export type CursorPaginationInfo = {
  __typename?: 'CursorPaginationInfo';
  afterCursor?: Maybe<Scalars['String']>;
  beforeCursor?: Maybe<Scalars['String']>;
  count: Scalars['Int'];
  hasNext?: Maybe<Scalars['Boolean']>;
  hasPrevious?: Maybe<Scalars['Boolean']>;
};

export type DateRange = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};


export type IncrementClientOnLocationPayload = Location | MaxCapacityReachedError;

export enum Locale {
  Asar = 'ASAR',
  Auen = 'AUEN',
  Cncn = 'CNCN',
  Cncns = 'CNCNS',
  Cscs = 'CSCS',
  Dede = 'DEDE',
  Elel = 'ELEL',
  Eses = 'ESES',
  Frfr = 'FRFR',
  Huhu = 'HUHU',
  Itit = 'ITIT',
  Jpjp = 'JPJP',
  Krkr = 'KRKR',
  Nlnl = 'NLNL',
  Ptbr = 'PTBR',
  Ptpt = 'PTPT',
  Ruru = 'RURU',
  Thth = 'THTH',
  Uken = 'UKEN',
  Usen = 'USEN',
  Vivi = 'VIVI'
}

export type Location = {
  __typename?: 'Location';
  brand: Brand;
  brandId: Scalars['String'];
  canIncrease: Scalars['Boolean'];
  capacity: Scalars['Int'];
  createdDate: Scalars['DateTime'];
  currentTraffic: Scalars['Int'];
  defaultLanguageLocale: Locale;
  durationBeforeReminder: Scalars['Int'];
  enableBooking: Scalars['Boolean'];
  extraWaitingTime: Scalars['Int'];
  id: Scalars['ID'];
  locationCode?: Maybe<Scalars['String']>;
  locationType: LocationType;
  maxPartySize: Scalars['Int'];
  members: Array<Member>;
  name: Scalars['String'];
  nextInLineNotification: Scalars['Int'];
  queueEnabled: Scalars['Boolean'];
  queueRequired: Scalars['Boolean'];
  queueStarted: Scalars['Boolean'];
  registrationLink: Scalars['String'];
  showUpDuration: Scalars['Int'];
  timezone: Scalars['String'];
  updatedDate: Scalars['DateTime'];
};


export type LocationMembersArgs = {
  filter?: Maybe<MemberFilter>;
};

export type LocationState = {
  __typename?: 'LocationState';
  brandId: Scalars['String'];
  brandName: Scalars['String'];
  defaultBrandLocales: Array<Locale>;
  defaultLanguageLocale: Locale;
  enableBooking: Scalars['Boolean'];
  locationCode?: Maybe<Scalars['String']>;
  maxPartySize: Scalars['Int'];
  memberCount: Scalars['Int'];
  name: Scalars['String'];
  queueEnabled: Scalars['Boolean'];
};

export enum LocationType {
  Store = 'STORE'
}

export type MaxCapacityReachedError = {
  __typename?: 'MaxCapacityReachedError';
  message: Scalars['String'];
};

export type Member = {
  __typename?: 'Member';
  acceptedDate?: Maybe<Scalars['DateTime']>;
  canAccept: Scalars['Boolean'];
  canceledDate?: Maybe<Scalars['DateTime']>;
  clientId?: Maybe<Scalars['String']>;
  createdDate: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastPosition: Scalars['Int'];
  lastname?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  locationId: Scalars['String'];
  numberOfGuests: Scalars['Int'];
  originalPosition: Scalars['Int'];
  otherReason?: Maybe<Scalars['DateTime']>;
  phoneCountry?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phonePrefix?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['DateTime']>;
  removedDate?: Maybe<Scalars['DateTime']>;
  status: MemberStatus;
  updatedDate: Scalars['DateTime'];
};

export type MemberFilter = {
  acceptedDate: DateRange;
  status?: Maybe<Array<MemberStatus>>;
};

export type MemberState = {
  __typename?: 'MemberState';
  brandLanguagesLocale: Array<Locale>;
  brandName: Scalars['String'];
  clientId?: Maybe<Scalars['String']>;
  firstname: Scalars['String'];
  languageLocale: Locale;
  lastname: Scalars['String'];
  locationCode?: Maybe<Scalars['String']>;
  locationLanguageLocale: Locale;
  maxPartySize: Scalars['Int'];
  memberId: Scalars['ID'];
  numberOfGuests: Scalars['Int'];
  otherReason?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  reason?: Maybe<Scalars['String']>;
  status: MemberStatus;
  storeName: Scalars['String'];
};

export enum MemberStatus {
  Accepted = 'ACCEPTED',
  Canceled = 'CANCELED',
  Registered = 'REGISTERED',
  Removed = 'REMOVED'
}

export type Mutation = {
  __typename?: 'Mutation';
  acceptMember: AcceptMemberPayload;
  cancelRegistration: Scalars['Boolean'];
  decrementClientFromLocation: Location;
  incrementClientOnLocation: IncrementClientOnLocationPayload;
  registerMember: MemberState;
  removeMember: Scalars['Boolean'];
};


export type MutationAcceptMemberArgs = {
  memberId: Scalars['String'];
};


export type MutationCancelRegistrationArgs = {
  memberId: Scalars['String'];
};


export type MutationDecrementClientFromLocationArgs = {
  id: Scalars['String'];
};


export type MutationIncrementClientOnLocationArgs = {
  id: Scalars['String'];
  numberOfGuests?: Maybe<Scalars['Int']>;
};


export type MutationRegisterMemberArgs = {
  input: RegisterMemberInput;
  locationId: Scalars['String'];
};


export type MutationRemoveMemberArgs = {
  memberId: Scalars['String'];
};

export type PaginationInfo = {
  __typename?: 'PaginationInfo';
  count: Scalars['Int'];
  hasNext?: Maybe<Scalars['Boolean']>;
  hasPrevious?: Maybe<Scalars['Boolean']>;
  limit: Scalars['Int'];
  start: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  getActionLinks: ActionLinks;
  getLocation: Location;
  getLocationState: LocationState;
  getMemberState: MemberState;
  getRegistrationLink: Scalars['String'];
  getTranslatedReasons: Array<TranslatedReason>;
  /** @deprecated Use getActionLinks instead. */
  getWireLink?: Maybe<Scalars['String']>;
};


export type QueryGetActionLinksArgs = {
  locationId?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['String']>;
};


export type QueryGetLocationArgs = {
  id: Scalars['String'];
};


export type QueryGetLocationStateArgs = {
  locationId: Scalars['String'];
};


export type QueryGetMemberStateArgs = {
  memberId: Scalars['String'];
};


export type QueryGetRegistrationLinkArgs = {
  id: Scalars['String'];
};


export type QueryGetTranslatedReasonsArgs = {
  brandId: Scalars['String'];
  locale: Locale;
};


export type QueryGetWireLinkArgs = {
  memberId: Scalars['String'];
};

export enum ReasonToVisit {
  DiscoverBrandCollection = 'DISCOVER_BRAND_COLLECTION',
  LookGift = 'LOOK_GIFT',
  Other = 'OTHER',
  SelfPurchase = 'SELF_PURCHASE',
  ServiceEnquiry = 'SERVICE_ENQUIRY'
}

export type RegisterMemberInput = {
  firstname: Scalars['String'];
  languageLocale?: Maybe<Locale>;
  lastname: Scalars['String'];
  numberOfGuests?: Maybe<Scalars['Int']>;
  otherReason?: Maybe<Scalars['String']>;
  phoneCountry: Scalars['String'];
  phoneNumber: Scalars['String'];
  phonePrefix: Scalars['String'];
  reason?: Maybe<ReasonToVisit>;
};

export type TranslatedReason = {
  __typename?: 'TranslatedReason';
  key: ReasonToVisit;
  text: Scalars['String'];
};

export type CancelRegistrationMutationVariables = Exact<{
  memberId: Scalars['String'];
}>;


export type CancelRegistrationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelRegistration'>
);

export type GetActionLinksQueryVariables = Exact<{
  locationId?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['String']>;
}>;


export type GetActionLinksQuery = (
  { __typename?: 'Query' }
  & { actionLinks: (
    { __typename?: 'ActionLinks' }
    & Pick<ActionLinks, 'wireLink' | 'bookAnAppointment'>
  ) }
);

export type GetLocationStateQueryVariables = Exact<{
  locationId: Scalars['String'];
}>;


export type GetLocationStateQuery = (
  { __typename?: 'Query' }
  & { locationState: (
    { __typename?: 'LocationState' }
    & Pick<LocationState, 'queueEnabled' | 'name' | 'locationCode' | 'maxPartySize' | 'memberCount' | 'defaultBrandLocales' | 'defaultLanguageLocale' | 'brandName' | 'brandId'>
  ) }
);

export type GetMemberStateQueryVariables = Exact<{
  memberId: Scalars['String'];
}>;


export type GetMemberStateQuery = (
  { __typename?: 'Query' }
  & { memberState: (
    { __typename?: 'MemberState' }
    & Pick<MemberState, 'memberId' | 'position' | 'status' | 'clientId' | 'storeName' | 'firstname' | 'lastname' | 'numberOfGuests' | 'maxPartySize' | 'brandLanguagesLocale' | 'locationLanguageLocale' | 'languageLocale' | 'locationCode' | 'brandName' | 'reason' | 'otherReason'>
  ) }
);

export type GetTranslatedReasonsQueryVariables = Exact<{
  brandId: Scalars['String'];
  locale: Locale;
}>;


export type GetTranslatedReasonsQuery = (
  { __typename?: 'Query' }
  & { translatedReasons: Array<(
    { __typename?: 'TranslatedReason' }
    & Pick<TranslatedReason, 'key' | 'text'>
  )> }
);

export type RegisterMemberMutationVariables = Exact<{
  locationId: Scalars['String'];
  input: RegisterMemberInput;
}>;


export type RegisterMemberMutation = (
  { __typename?: 'Mutation' }
  & { registerMember: (
    { __typename?: 'MemberState' }
    & Pick<MemberState, 'memberId' | 'clientId' | 'locationCode' | 'brandName' | 'numberOfGuests' | 'reason' | 'otherReason'>
  ) }
);


export const CancelRegistrationDocument = gql`
    mutation cancelRegistration($memberId: String!) {
  cancelRegistration(memberId: $memberId)
}
    `;
export type CancelRegistrationMutationFn = Apollo.MutationFunction<CancelRegistrationMutation, CancelRegistrationMutationVariables>;

/**
 * __useCancelRegistrationMutation__
 *
 * To run a mutation, you first call `useCancelRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelRegistrationMutation, { data, loading, error }] = useCancelRegistrationMutation({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useCancelRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<CancelRegistrationMutation, CancelRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelRegistrationMutation, CancelRegistrationMutationVariables>(CancelRegistrationDocument, options);
      }
export type CancelRegistrationMutationHookResult = ReturnType<typeof useCancelRegistrationMutation>;
export type CancelRegistrationMutationResult = Apollo.MutationResult<CancelRegistrationMutation>;
export type CancelRegistrationMutationOptions = Apollo.BaseMutationOptions<CancelRegistrationMutation, CancelRegistrationMutationVariables>;
export const GetActionLinksDocument = gql`
    query getActionLinks($locationId: String, $memberId: String) {
  actionLinks: getActionLinks(locationId: $locationId, memberId: $memberId) {
    wireLink
    bookAnAppointment
  }
}
    `;

/**
 * __useGetActionLinksQuery__
 *
 * To run a query within a React component, call `useGetActionLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActionLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActionLinksQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useGetActionLinksQuery(baseOptions?: Apollo.QueryHookOptions<GetActionLinksQuery, GetActionLinksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActionLinksQuery, GetActionLinksQueryVariables>(GetActionLinksDocument, options);
      }
export function useGetActionLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActionLinksQuery, GetActionLinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActionLinksQuery, GetActionLinksQueryVariables>(GetActionLinksDocument, options);
        }
export type GetActionLinksQueryHookResult = ReturnType<typeof useGetActionLinksQuery>;
export type GetActionLinksLazyQueryHookResult = ReturnType<typeof useGetActionLinksLazyQuery>;
export type GetActionLinksQueryResult = Apollo.QueryResult<GetActionLinksQuery, GetActionLinksQueryVariables>;
export const GetLocationStateDocument = gql`
    query getLocationState($locationId: String!) {
  locationState: getLocationState(locationId: $locationId) {
    queueEnabled
    name
    locationCode
    maxPartySize
    memberCount
    defaultBrandLocales
    defaultLanguageLocale
    brandName
    brandId
  }
}
    `;

/**
 * __useGetLocationStateQuery__
 *
 * To run a query within a React component, call `useGetLocationStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationStateQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetLocationStateQuery(baseOptions: Apollo.QueryHookOptions<GetLocationStateQuery, GetLocationStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationStateQuery, GetLocationStateQueryVariables>(GetLocationStateDocument, options);
      }
export function useGetLocationStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationStateQuery, GetLocationStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationStateQuery, GetLocationStateQueryVariables>(GetLocationStateDocument, options);
        }
export type GetLocationStateQueryHookResult = ReturnType<typeof useGetLocationStateQuery>;
export type GetLocationStateLazyQueryHookResult = ReturnType<typeof useGetLocationStateLazyQuery>;
export type GetLocationStateQueryResult = Apollo.QueryResult<GetLocationStateQuery, GetLocationStateQueryVariables>;
export const GetMemberStateDocument = gql`
    query getMemberState($memberId: String!) {
  memberState: getMemberState(memberId: $memberId) {
    memberId
    position
    status
    clientId
    storeName
    firstname
    lastname
    numberOfGuests
    maxPartySize
    brandLanguagesLocale
    locationLanguageLocale
    languageLocale
    locationCode
    brandName
    reason
    otherReason
  }
}
    `;

/**
 * __useGetMemberStateQuery__
 *
 * To run a query within a React component, call `useGetMemberStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberStateQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useGetMemberStateQuery(baseOptions: Apollo.QueryHookOptions<GetMemberStateQuery, GetMemberStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMemberStateQuery, GetMemberStateQueryVariables>(GetMemberStateDocument, options);
      }
export function useGetMemberStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemberStateQuery, GetMemberStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMemberStateQuery, GetMemberStateQueryVariables>(GetMemberStateDocument, options);
        }
export type GetMemberStateQueryHookResult = ReturnType<typeof useGetMemberStateQuery>;
export type GetMemberStateLazyQueryHookResult = ReturnType<typeof useGetMemberStateLazyQuery>;
export type GetMemberStateQueryResult = Apollo.QueryResult<GetMemberStateQuery, GetMemberStateQueryVariables>;
export const GetTranslatedReasonsDocument = gql`
    query getTranslatedReasons($brandId: String!, $locale: Locale!) {
  translatedReasons: getTranslatedReasons(brandId: $brandId, locale: $locale) {
    key
    text
  }
}
    `;

/**
 * __useGetTranslatedReasonsQuery__
 *
 * To run a query within a React component, call `useGetTranslatedReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTranslatedReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTranslatedReasonsQuery({
 *   variables: {
 *      brandId: // value for 'brandId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetTranslatedReasonsQuery(baseOptions: Apollo.QueryHookOptions<GetTranslatedReasonsQuery, GetTranslatedReasonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTranslatedReasonsQuery, GetTranslatedReasonsQueryVariables>(GetTranslatedReasonsDocument, options);
      }
export function useGetTranslatedReasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTranslatedReasonsQuery, GetTranslatedReasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTranslatedReasonsQuery, GetTranslatedReasonsQueryVariables>(GetTranslatedReasonsDocument, options);
        }
export type GetTranslatedReasonsQueryHookResult = ReturnType<typeof useGetTranslatedReasonsQuery>;
export type GetTranslatedReasonsLazyQueryHookResult = ReturnType<typeof useGetTranslatedReasonsLazyQuery>;
export type GetTranslatedReasonsQueryResult = Apollo.QueryResult<GetTranslatedReasonsQuery, GetTranslatedReasonsQueryVariables>;
export const RegisterMemberDocument = gql`
    mutation registerMember($locationId: String!, $input: RegisterMemberInput!) {
  registerMember: registerMember(locationId: $locationId, input: $input) {
    memberId
    clientId
    locationCode
    brandName
    numberOfGuests
    reason
    otherReason
  }
}
    `;
export type RegisterMemberMutationFn = Apollo.MutationFunction<RegisterMemberMutation, RegisterMemberMutationVariables>;

/**
 * __useRegisterMemberMutation__
 *
 * To run a mutation, you first call `useRegisterMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMemberMutation, { data, loading, error }] = useRegisterMemberMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMemberMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMemberMutation, RegisterMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMemberMutation, RegisterMemberMutationVariables>(RegisterMemberDocument, options);
      }
export type RegisterMemberMutationHookResult = ReturnType<typeof useRegisterMemberMutation>;
export type RegisterMemberMutationResult = Apollo.MutationResult<RegisterMemberMutation>;
export type RegisterMemberMutationOptions = Apollo.BaseMutationOptions<RegisterMemberMutation, RegisterMemberMutationVariables>;