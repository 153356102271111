import mixpanel from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Country, getCountryCallingCode } from 'react-phone-number-input';
import { Redirect, useParams } from 'react-router';

import {
  GetLocationStateQuery,
  RegisterMemberInput,
  RegisterMemberMutation,
  TranslatedReason,
  useRegisterMemberMutation,
} from '../../generated/graphql';
import { Loader } from '../../Shared/Components';
import { withBrand, WithBrandProps } from '../../Shared/Components/withBrand';
import { useGetLocationState } from '../../Shared/Hooks/useGetLocationState';
import { useGetTranslatedReasons } from '../../Shared/Hooks/useGetTranslatedReasons';
import { useQm } from '../../Shared/Hooks/useQm';
import { getPrivacyPolicyLinkFromBrandCode } from '../../utils/brands';

import { RegisterForm } from '../Components/registerForm';

/**
 * RegistrationContainer
 */
function RegistrationContainer({ brandCode }: WithBrandProps): JSX.Element {
  const [submitted, setSubmitted] = useState<boolean>(false);

  const { t } = useTranslation();
  const { getCurrentLocale, initLocales } = useQm();

  const { locationId } = useParams<{ locationId: string }>();
  const { data: locationStateData, loading: locationStateLoading } = useGetLocationState(locationId);

  const [
    registerMember,
    { data: registerMemberData, loading: registerMemberLoading, error: registerMemberError },
  ] = useRegisterMemberMutation();

  const privacyPolicyLink: string = getPrivacyPolicyLinkFromBrandCode(brandCode);

  const locationState: GetLocationStateQuery['locationState'] | undefined =
    locationStateData?.locationState ?? undefined;

  useEffect(
    () => {
      if (locationState !== undefined) {
        initLocales(
          locationState.defaultBrandLocales,
          locationState.defaultLanguageLocale,
          undefined,
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locationState],
  );

  const {
    data: translatedReasonsData,
    loading: translatedReasonsLoading,
  } = useGetTranslatedReasons(locationState?.brandId ?? '', getCurrentLocale());

  if (locationStateLoading || registerMemberLoading || translatedReasonsLoading) {
    return (
      <Loader full />
    );
  }
  if (locationState === undefined || registerMemberError !== undefined) {
    return (
      <Redirect to={{ pathname: '/error', state: { error: 'queueDisabled', locationId } }} />
    );
  }

  const translatedReasons: TranslatedReason[] =
    translatedReasonsData?.translatedReasons ?? [];

  const onSubmit: any = async (
    { firstname, lastname, numberOfGuests, phoneCountry, phoneNumber, reason, otherReason }: RegisterMemberInput,
  ) => {
    const phonePrefix: string = `+${getCountryCallingCode(phoneCountry as Country) as string}`;

    await registerMember({
      variables: {
        locationId,
        input: {
          firstname,
          lastname,
          phonePrefix,
          phoneCountry,
          phoneNumber,
          numberOfGuests,
          languageLocale: getCurrentLocale(),
          reason,
          otherReason,
        },
      },
    });

    setSubmitted(true);
  };

  const memberState: RegisterMemberMutation['registerMember'] | undefined =
    registerMemberData?.registerMember ?? undefined;

  if (submitted && memberState !== undefined) {
    mixpanel.identify(memberState.memberId);

    mixpanel.track('Join the queue', {
      numberOfGuests: memberState.numberOfGuests,
      isClient: memberState.clientId !== null,
      reason: memberState.reason,
      otherReason: memberState.otherReason,
    });

    mixpanel.people.set({
      brandName: memberState.brandName,
      storeCode: memberState.locationCode,
    });

    return <Redirect push to={{ pathname: `/m/${memberState.memberId}/s` }} />;
  }

  return (
    <>
      <h2>{t('registration.signUp')}</h2>
      <RegisterForm
        locationState={locationState}
        translatedReasons={translatedReasons}
        privacyPolicyLink={privacyPolicyLink}
        brandCode={brandCode}
        onSubmit={onSubmit}
      />
    </>
  );
}

/**
 * Registration
 */
// tslint:disable-next-line: variable-name
export const Registration: any = withBrand(RegistrationContainer, 'sign-up-queue');
