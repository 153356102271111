import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { GetLocationStateQuery } from '../generated/graphql';
import { Loader, withBrand } from '../Shared/Components';
import { useGetLocationState } from '../Shared/Hooks/useGetLocationState';

/**
 * ErrorComponent
 */
export function ErrorComponent(): JSX.Element {
  const location: any = useLocation();

  const { t } = useTranslation();

  const { data, loading } = useGetLocationState(location.state?.locationId ?? '');
  const locationState: GetLocationStateQuery['locationState'] | undefined = data?.locationState ?? undefined;

  if (loading) {
    return (
      <Loader full />
    );
  }

  const errorType: string = locationState !== undefined ? location.state.error : undefined;
  const baseKey: string = 'errors';

  switch (errorType) {
    case 'queueDisabled':
      return (
        <p>{t(`${baseKey}.queueDisabled`, { brand: locationState?.brandName, storeName: locationState?.name })}</p>
      );

    default:
      return (
        <p>{t(`${baseKey}.global`)}</p>
      );
  }
}

/**
 * Error
 */
// tslint:disable-next-line: variable-name
export const Error: any = withBrand(ErrorComponent, 'error', true);
