import mixpanel from 'mixpanel-browser';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { MemberStatus } from '../../generated/graphql';

import { CancelledProps } from '../Types/cancelled';

/**
 * Canceled
 */
export function Canceled({ memberState }: CancelledProps): JSX.Element {
  const { t } = useTranslation();

  if (memberState.status === MemberStatus.Removed) {
    mixpanel.identify(memberState.memberId);

    mixpanel.track('Auto removed client');
    mixpanel.disable(['Auto removed client'])

    mixpanel.people.set({
      brandName: memberState?.brandName,
      storeCode: memberState?.locationCode,
    });
  }

  return (
    <div id='status-canceled' className='fullpage'>
      <p>{t('status.canceled.confirm')}</p>
      <p>{t('status.canceled.thanks')}</p>
    </div>
  );
}
