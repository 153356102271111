import { QueryResult } from '@apollo/client';

import { GetLocationStateQuery, useGetLocationStateQuery } from '../../generated/graphql';

/**
 * useGetLocationState
 */
export function useGetLocationState(locationId: string): QueryResult<GetLocationStateQuery> {
  return useGetLocationStateQuery({
    variables: { locationId },
  });
}
