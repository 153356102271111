import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import type { Country} from 'react-phone-number-input';
import { getCountryCallingCode, isSupportedCountry, isValidPhoneNumber } from 'react-phone-number-input';

import type { RegisterMemberInput, TranslatedReason } from '../../generated/graphql';
import { ReasonToVisit } from '../../generated/graphql';
import { FooterButton, LinkText } from '../../Shared/Components';
import { getBrandSlugFromBrandCode } from '../../utils/brands';

import type { RegisterFormProps } from '../Types/registerForm';

import { CountrySelect } from './countrySelect';

const keyPrefix: string = 'registration.registerForm';

const MIN_CHAR_NAME = 1;

function validate(maxPartySize: number, reasonMandatory: boolean, t: any): (values: any) => any {
  return (values: any) => {
    const errors: {
      firstname: string;
      lastname: string;
      phonePrefix: string;
      phoneCountry: string;
      phoneNumber: string;
      numberOfGuests: string;
      privacy: string;
      reason: string;
      otherReason: string;
    } = {
      firstname: '',
      lastname: '',
      phonePrefix: '',
      phoneCountry: '',
      phoneNumber: '',
      numberOfGuests: '',
      privacy: '',
      reason: '',
      otherReason: '',
    };

    if (!values.firstname) {
      errors.firstname = t('errors.required');
    } else if (values.firstname.length < MIN_CHAR_NAME) {
      errors.firstname = t('errors.minLength', { val: MIN_CHAR_NAME });
    }

    if (!values.lastname) {
      errors.lastname = t('errors.required');
    } else if (values.lastname.length < MIN_CHAR_NAME) {
      errors.lastname = t('errors.minLength', { val: MIN_CHAR_NAME });
    }

    if (!values.phoneCountry) {
      errors.phoneCountry = t('errors.required');
    } else if (!isSupportedCountry(values.phoneCountry)) {
      errors.phoneCountry = t('errors.invalidCountryCode');
    }

    if (!values.phoneNumber) {
      errors.phoneNumber = t('errors.required');
    } else if (isSupportedCountry(values.phoneCountry) && !isValidPhoneNumber(`+${(getCountryCallingCode(values.phoneCountry as Country) as string)} ${values.phoneNumber}`)) {
      errors.phoneNumber = t('errors.invalidPhoneNumber');
    }

    if (!values.numberOfGuests) {
      errors.numberOfGuests = t('errors.required');
    } else if (values.numberOfGuests < 1) {
      errors.numberOfGuests = t('errors.minNumberOfGuests', { val: 1 });
    } else if (values.numberOfGuests > maxPartySize) {
      errors.numberOfGuests = t('errors.maxNumberOfGuests', { val: maxPartySize });
    }

    if (values.privacy === false) {
      errors.privacy = t('errors.privacy');
    }

    if (reasonMandatory && !values.reason) {
      errors.reason = t('errors.required');
    } else if (values.reason === ReasonToVisit.Other && !values.otherReason) {
      errors.otherReason = t('errors.required');
    }

    if (Object.keys(errors).some((k: string) => (errors as any)[k] !== '')) {
      return errors;
    }

    return {};
  };
}

function getCountry(): string {
  const localeFromDate: string = Intl.DateTimeFormat().resolvedOptions().locale;

  return localeFromDate.length > 2 ? localeFromDate.slice(-2) : localeFromDate;
}

/**
 * RegisterForm
 */
export function RegisterForm(
  { locationState, translatedReasons, privacyPolicyLink, brandCode, onSubmit }: RegisterFormProps,
): JSX.Element {
  const initialValues: RegisterMemberInput & { privacy: boolean } = {
    firstname: '',
    lastname: '',
    phonePrefix: '',
    phoneCountry: '',
    phoneNumber: '',
    numberOfGuests: 1,
    reason: undefined,
    otherReason: undefined,
    privacy: false,
  };

  const { t, i18n } = useTranslation('translation', { keyPrefix });
  const reasonMandatory: boolean = translatedReasons.length !== 0;
  const { handleSubmit, handleChange, setFieldValue, values, errors } = useFormik({
    initialValues,
    onSubmit,
    validate: validate(locationState.maxPartySize, reasonMandatory, t),
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleCountryCodeChange: (countryCode: string) => void = (countryCode: string) => {
    setFieldValue('phoneCountry', countryCode);
  };

  const brandSlug: string = getBrandSlugFromBrandCode(brandCode);

  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [showPrivacyDisclaimer, setShowPrivacyDisclaimer] = useState(false);
  const handleClosePrivacyModal: () => void = () => setShowPrivacyModal(false);
  const handleShowPrivacyModal: () => void = () => setShowPrivacyModal(true);

  useEffect(
    () => {
      if (i18n.exists(`${keyPrefix}.privacy.more.details.${brandSlug}`)) {
        if (t(`privacy.more.details.${brandSlug}`) !== '') {
          setShowPrivacyDisclaimer(true);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n],
  );

  const reasonOptions: JSX.Element[] = translatedReasons.map((reason: TranslatedReason) =>
    <option key={reason.key} value={reason.key}>{reason.text}</option>,
  );

  const country: string = getCountry();

  return (
    <div id='register-form'>
      <Form noValidate onSubmit={handleSubmit}>
        <div className='form-top'>
          <Row>
            <Form.Group>
              <Form.Label>{t('firstname')}</Form.Label>
              <Form.Control
                type='text'
                onChange={handleChange}
                name='firstname'
                value={values.firstname}
              ></Form.Control>
              <Form.Text className='text-danger font-weight-bold'>
                {errors.firstname}
              </Form.Text>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className={'col-12'}>
              <Form.Label>{t('lastname')}</Form.Label>
              <Form.Control
                type='text'
                onChange={handleChange}
                name='lastname'
                value={values.lastname}
              ></Form.Control>
              <Form.Text className='text-danger font-weight-bold'>
                {errors.lastname}
              </Form.Text>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className={'col-12'}>
              <Form.Label>{t('partySize')}</Form.Label>
              <Form.Control
                type='number'
                onChange={handleChange}
                name='numberOfGuests'
                value={values.numberOfGuests ?? 1}
                min='1'
                max={`${locationState.maxPartySize}`}
              ></Form.Control>
              <Form.Text className='text-danger font-weight-bold'>
                {errors.numberOfGuests}
              </Form.Text>
            </Form.Group>
          </Row>

          <Row>
            <div className={'col-12'}>
              <Form.Label>{t('phoneNumber')}</Form.Label>
            </div>
            <Form.Group className={'col-4'}>
              <CountrySelect
                country={country}
                onChange={handleCountryCodeChange}
                value={values.phoneCountry}
              />
              <Form.Text className='text-danger font-weight-bold'>
                {errors.phoneCountry}
              </Form.Text>
            </Form.Group>
            <Form.Group className={'col-8'}>
              <Form.Control
                type='text'
                onChange={handleChange}
                name='phoneNumber'
                value={values.phoneNumber}
              />
              <Form.Text className='text-danger font-weight-bold'>
                {errors.phoneNumber}
              </Form.Text>
            </Form.Group>
          </Row>
          {
            reasonMandatory
              ?
              <Row>
                <Form.Group className='col-12'>
                  <Form.Label>{t('reason')}</Form.Label>
                  <Form.Control
                    as='select'
                    onChange={handleChange}
                    name='reason'
                    value={values.reason ?? ''}
                  >
                    <option></option>
                    {reasonOptions}
                  </Form.Control>
                  <Form.Text className='text-danger font-weight-bold'>
                    {errors.reason}
                  </Form.Text>
                </Form.Group>
              </Row>
              : <></>
          }
          {
            values.reason === ReasonToVisit.Other
              ?
              <Row>
                <Form.Group className={'col-12'}>
                  <Form.Label>{t('otherReason')}</Form.Label>
                  <Form.Control
                    type='text'
                    onChange={handleChange}
                    name='otherReason'
                    value={values.otherReason ?? ''}
                  ></Form.Control>
                  <Form.Text className='text-danger font-weight-bold'>
                    {errors.otherReason}
                  </Form.Text>
                </Form.Group>
              </Row>
              : <></>
          }
          <p className='sms-notice'>
            {t('smsNotice')}
          </p>
        </div>
        <div className='form-bottom'>
          <div className='additional-info'>
            <Row className='privacy-check'>
              <Form.Group className={'col-12'}>
                <Form.Check
                  className='sms-privacy-policy-checkbox'
                >
                  <input
                    type='checkbox'
                    id='privacy'
                    onChange={handleChange}
                    checked={values.privacy}
                    name='privacy'
                    className='custom-control-input' />
                  <label className='custom-control-label' htmlFor='privacy'>
                    <Trans
                      i18nKey={'registration.registerForm.privacy.confirm'}
                      values={{ privacyPolicyLink }}
                      components={{
                        privacyLink: <LinkText to={privacyPolicyLink} title={t('privacy.more.title')} newPage />,
                      }}
                    />
                  </label>
                </Form.Check>
                <Form.Text className='text-danger font-weight-bold'>
                  {errors.privacy}
                </Form.Text>
              </Form.Group>
            </Row>
            {
              showPrivacyDisclaimer || true ?
                <Row>
                  <Form.Group className={'col-12'}>
                    <p className='privacy-more'>
                      <Button variant={'link'} onClick={handleShowPrivacyModal}>
                        {t('privacy.more.what')}
                      </Button>
                    </p>
                  </Form.Group>
                </Row>
                :
                <></>
            }
          </div>
          <div className='register-bottom'>
            <FooterButton
              text={t('signUp')}
              type='submit'
            />
          </div>
        </div>
      </Form>
      <Modal
        centered
        animation={false}
        show={showPrivacyModal}
        className={`brand-${brandSlug} custom-modal`}
        onHide={handleClosePrivacyModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('privacy.more.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Trans
            i18nKey={`${keyPrefix}.privacy.more.details.${brandSlug}`}
            values={{
              link: privacyPolicyLink,
            }}
            components={{
              privacyLink: <LinkText to={privacyPolicyLink} title={t('privacy.more.title')} newPage />,
            }}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
