import React from 'react';
import { Button } from 'react-bootstrap';

/**
 * FooterButtonProps
 */
interface FooterButtonProps {
  text: string;
  enabled?: boolean;
  type?: string;

  /**
   * onClick
   */
  onClick?(d: any): void;
}

/**
 * FooterButton
 */
export function FooterButton({ text, enabled, type, onClick }: FooterButtonProps): JSX.Element {
  return (
    <Button
      className='footer-button'
      disabled={!(enabled ?? true)}
      type={type as any ?? 'button'}
      onClick={onClick}
    >
      {text}
    </Button>
  );
}
