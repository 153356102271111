import type { TranslationType } from 'i18n/type';

const data: TranslationType = {
  language: 'English',
  registration: {
    signUp: 'Sign up for the queue',
    registerForm: {
      firstname: 'First name',
      lastname: 'Last name',
      partySize: 'How many people are in your party?',
      phoneNumber: 'Phone number',
      smsNotice: 'SMS updates regarding your queue status will be sent to this phone number. Standard rates apply.',
      reason: 'What\'s the purpose of your visit?',
      otherReason: 'If other, please specify',
      noOptions: 'No options',
      privacy: {
        confirm: 'Select this box to certify that the number you\'ve provided is your own number and that you agree to the <privacyLink>Privacy Policy</privacyLink>.',
        more: {
          title: 'Personal data',
          what: 'How do we use your personal data?',
          details: {
            sl: 'YVES SAINT LAURENT SAS processes your personal data, as controller, in order to: (i) manage queuing at the entrance of the points of sale; and (ii) provided you already have a profile, recognize you, before you access the point of sale, for optimizing your reception. You benefit from various rights regarding your personal data. For additional information on the processing of your personal data (including how you can exercise your rights), please click here <privacyLink>{{link}}</privacyLink>.',
            balenciaga: '',
            gucci: 'Guccio Gucci S.p.A. [\'Gucci \'we\' \'us\'), in its quality of data controller, Intends to inform you that it will process the data you provided us in order to: (i) manage queuing at the entrance of the points of sale; and (ii) provided you already have a profile, recognize you, before you access the point of sale, for optimizing your reception.  The processing of your data will be carried out by authorized personnel according to the law, only in order to provide you the requested service. Your personal data will not be transferred outside the country where it has been originally collected or the EEA, disclosed or otherwise communicated to any third parties. You can exercise your rights at any time according to your applicable privacy legislation, by sending an email to privacy@gucci.com. Further detailed information on your rights are available at the following link (<privacyLink>{{link}}</privacyLink>).',
            amq: '',
            brioni: '',
            bv: '',
          },
        },
      },
      signUp: 'Confirm',
      countryCode: 'international code',
      errors: {
        required: 'Required',
        minLength: 'Must be 2 characters or more',
        minNumberOfGuests: 'Must be at least 1',
        maxNumberOfGuests: 'Must be at maximum {{val, number}}',
        invalidCountryCode: 'Invalid country code',
        invalidPhoneNumber: 'Invalid phone number',
        privacy: 'You must agree to the terms',
      },
    },
  },
  shared: {
    loading: 'Loading...',
  },
  status: {
    accepted: 'You\'ve been already accepted to the store.',
    canceled: {
      confirm: 'You\'ve been successfully removed from the queue.',
      thanks: 'We will be happy to see you next time.',
    },
    registered: {
      description: {
        part1: 'Thank you {{firstname}}!',
        part2: 'You are now',
        part3: 'the next',
        part4: 'in the queue for "{{storeName}}"',
      },
      next: 'It\'s now your turn to enter the store! Please scan the QR Code at the store entrance',
      cancellation: 'No longer interested in visiting our store? <cancelLink>Click here to leave the queue</cancelLink>.',
      register: {
        status: 'Register to receive a personalized in-store experience every time you shop with us.',
        button: 'Register my profile',
      },
      cancel: {
        title: 'Leave Queue',
        body: 'If you leave the queue now you will lose your place.',
        secondary: 'Stay',
        primary: 'Leave',
      },
      bookAnAppointment: 'Skip the queue and <bookingLink>book an appointment</bookingLink>',
    },
  },
  errors: {
    global: 'Sorry, something went wrong.',
    queueDisabled: 'The queue for {{brand}} {{storeName}} is closed',
  },
  welcome: {
    language: 'Language:',
    info: 'Welcome, there are {{count}} people in the queue for {{storeName}}',
    signUp: 'Tap on sign up below to join the queue',
    action: 'Sign up',
  },
};

export default data;
