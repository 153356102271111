import { TranslationType } from 'i18n/type';

const data: TranslationType = {
  language: 'Korean',
  registration: {
    signUp: '대기등록을 진행하십시오',
    registerForm: {
      firstname: '이름',
      lastname: '성',
      partySize: '입장 인원은 몇 명인가요?',
      phoneNumber: '핸드폰 번호',
      smsNotice: '웨이팅 현황을 카카오톡 혹은 문자 메시지로 알려 드립니다',
      reason: '방문 목적이 무엇입니까?',
      otherReason: '그 외, 구체적으로 입력해주세요.',
      noOptions: '유효하지 않은 국가번호',
      privacy: {
        confirm: '입력하신 번호가 본인의 번호이며 <privacyLink>개인정보보호정책에</privacyLink> 동의하실 경우 박스를 선택하세요',
        more: {
          title: '개인 정보',
          what: '개인정보 수집 및 이용 목적',
          details: {
            sl: '입생로랑 SAS는 관리자로서 수집한 개인정보를 아래 목적으로 이용합니다. \ni) 입장을 위한 대기 순번 관리 \nii) 기존 고객 프로필이 있는 경우, 매장을 방문하시기 전 최적화된 응대 준비가 가능 \n제공자는 개인정보를 제공함으로써 다양한 권리를 보장받을 수 있습니다. \n개인 정보 취급에 대한 더 자세한 사항은 아래 링크를 통해 확인 부탁드립니다. <privacyLink>{{link}}</privacyLink>.',
            balenciaga: '',
            gucci: "Guccio Gucci S.p.A. ( 'Gucci', '저희')는 데이터 컨트롤러로서, 귀하가 제공한 데이터를 다음과 같은 목적으로 처리할 예정입니다: (i) 판매점 입구에서 대기열 관리 및 (ii) 이미 프로필을 보유하고 있을 경우 판매점에 접근하기 전에 귀하를 인식하여 귀하의 접수를 최적화하기 위함입니다. 귀하의 데이터 처리는 법률에 따라 허가된 직원에 의해 수행되며 요청한 서비스를 제공하는 데만 사용될 것입니다. 귀하의 개인 데이터는 원래 수집된 국가나 EEA(유럽 경제 지역) 이외로 전송되지 않으며 제3자에게 공개되거나 다른 방법으로 통보되지 않을 것입니다. 귀하는 privacy@gucci.com 로 이메일을 보내어 적용 가능한 개인 정보 보호 법률에 따라 언제든지 권리를 행사할 수 있습니다. 귀하의 권리에 대한 자세한 정보는 다음 링크(<privacyLink>{{link}}</privacyLink>)에서 확인하실 수 있습니다.",
            amq: '',
            brioni: '',
            bv: '',
          },
        },
      },
      signUp: '확인 필요 사항',
      countryCode: '국가 번호',
      errors: {
        required: '필수 사항',
        minLength: '{{val, number}} 문자 갯수 이상이어야 합니다.',
        minNumberOfGuests: '최소 {{val, number}}명 이상이어야 합니다.',
        maxNumberOfGuests: '최대 {{val, number}}명을 넘을 수 없습니다.',
        invalidCountryCode: '올바르지 않은 국가 코드',
        invalidPhoneNumber: '올바르지 않은 전화번호',
        privacy: '해당 조항에 동의합니다. ',
      },
    },
  },
  shared: {
    loading: '로딩 중...',
  },
  status: {
    accepted: '이미 웨이팅에 등록되었습니다.',
    canceled: {
       confirm: '대기 등록이 취소되었습니다. 다음 방문을 기대하겠습니다.',
      thanks: '다음 번에 뵙기를 바랍니다.',
    },
    registered: {
      description: {
        part1: '{{lastname}}{{firstname}} 고객님 께서는 {{storeName}} 스토어 대기 순번에서 다음 순서입니다.',
        part2: '스토어 ',
        part3: '대기 순번에서 ',
        part4: '다음 순서입니다.',
      },
      next: '고객님, 입장하실 차례입니다. 매장 입구에서 QR 코드를 스캔해주세요.',
      cancellation: '<cancelLink>대기를 취소하시겠습니까? 여기를 클릭해주세요.</cancelLink>',
      register: {
        status: '고객 정보를 등록하시면 다양한 서비스를 받으실 수 있습니다.',
        button: '정보 등록',
      },
      cancel: {
        title: '웨이팅 취소하기',
        body: '대기 등록을 취소하실 경우, 대기 순번이 초기화 됩니다.',
        secondary: '계속 대기',
        primary: '대기 취소',
      },
      bookAnAppointment: '줄을 서지 마시고 <bookingLink>매장 방문 예약</bookingLink>을 해보세요.',
    },
  },
  errors: {
    global: '죄송합니다. 정상적으로 진행되지 않았습니다. ',
    queueDisabled: '{{brand}} {{storeName}}의 대기가 종료되었습니다.',
  },
  welcome: {
    language: '언어',
    info: '환영합니다. {{storeName}} 대기 인원수는 {{count}} 입니다.',
    signUp: '대기 등록을 하기 위해 아래 등록 버튼을 탭해주세요.',
    action: '등록',
  },
};


export default data;
