import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

/**
 * LoaderProps
 */
interface LoaderProps {
  full?: boolean;
}

/**
 * Loader
 */
export function Loader({ full = false }: LoaderProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className={`loader ${full ? 'full' : ''}`}>
      <Spinner animation='border' role='status'>
        <span data-testid="spinner" className='visually-hidden'>{t('shared.loading')}</span>
      </Spinner>
    </div>
  );
}
