import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Accepted
 */
export function Accepted(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div id='status-accepted' className='fullpage'>
      <p>{t('status.accepted')}</p>
    </div>
  );
}
