import { Dictionary } from 'lodash';

import { Locale } from '../generated/graphql';

/**
 * Locale info interface
 */
interface LocaleInfo {
  language: string;
  label: string;
}

/**
 * languagesMap
 */
export const languagesMap: Dictionary<LocaleInfo> = {
  [Locale.Usen]: {
    language: 'en-US',
    label: 'English',
  },
  [Locale.Frfr]: {
    language: 'fr-FR',
    label: 'French',
  },
  [Locale.Dede]: {
    language: 'de-DE',
    label: 'German',
  },
  [Locale.Itit]: {
    language: 'it-IT',
    label: 'Italian',
  },
  [Locale.Eses]: {
    language: 'es-ES',
    label: 'Spanish',
  },
  [Locale.Jpjp]: {
    language: 'jp-JP',
    label: 'Japanese',
  },
  [Locale.Krkr]: {
    language: 'ko-KR',
    label: 'Korean',
  },
  [Locale.Thth]: {
    language: 'th-TH',
    label: 'Thai',
  },
  [Locale.Cncns]: {
    language: 'zh-CN',
    label: 'Chinese (Simplified)',
  },
  [Locale.Cncn]: {
    language: 'zh-TW',
    label: 'Chinese (traditional)',
  },
  [Locale.Asar]: {
    language: 'ar-AR',
    label: 'Arabic',
  },
  [Locale.Nlnl]: {
    language: 'nl-NL',
    label: 'Dutch',
  },
  [Locale.Ptpt]: {
    language: 'pt-PT',
    label: 'Portuguese',
  },
  [Locale.Ptbr]: {
    language: 'pt-BR',
    label: 'Portuguese (Brazilian)',
  },
};

/**
 * getLanguageLabelFromLocale
 */
const getLanguageLabelFromLocale: (locale: Locale) => string = (locale: Locale) =>
  locale in languagesMap ? languagesMap[locale].label : `No name ${locale}`;

/**
 * getLanguageFromLocale
 */
const getLanguageFromLocale: (locale: Locale) => string = (locale: Locale) =>
  locale in languagesMap ? languagesMap[locale].language : languagesMap[Locale.Usen].language;

/**
 * getLocaleFromLanguage
 */
const getLocaleFromLanguage:
  (language: string) => Locale = (language: string) => {
    switch (language) {
      case languagesMap[Locale.Frfr].language:
      case 'fr':
        return Locale.Frfr;
      case languagesMap[Locale.Dede].language:
      case 'de':
        return Locale.Dede;
      case languagesMap[Locale.Itit].language:
      case 'it':
        return Locale.Itit;
      case languagesMap[Locale.Usen].language:
      case 'en':
        return Locale.Usen;
      case languagesMap[Locale.Eses].language:
      case 'es':
        return Locale.Eses;
      case languagesMap[Locale.Jpjp].language:
      case 'jp':
        return Locale.Jpjp;
      case languagesMap[Locale.Krkr].language:
      case 'ko':
        return Locale.Krkr;
      case languagesMap[Locale.Thth].language:
      case 'th':
        return Locale.Thth;
      case languagesMap[Locale.Cncns].language:
      case 'chSimplified':
        return Locale.Cncns;
      case languagesMap[Locale.Cncn].language:
      case 'chTraditional':
        return Locale.Cncn;
      case languagesMap[Locale.Asar].language:
      case 'ar':
        return Locale.Asar;
      case languagesMap[Locale.Nlnl].language:
      case 'nl':
        return Locale.Nlnl;
      case languagesMap[Locale.Ptpt].language:
      case 'pt':
        return Locale.Ptpt;
      case languagesMap[Locale.Ptbr].language:
      case 'ptBr':
        return Locale.Ptbr;
      default:
        return Locale.Usen;
    }
  };

export {
  getLanguageLabelFromLocale,
  getLanguageFromLocale,
  getLocaleFromLanguage,
};
