import { TranslationType } from 'i18n/type';

const data: TranslationType = {
  language: 'Portuguese (Brazil)',
  registration: {
    signUp: 'Cadastre-se na fila',
    registerForm: {
      firstname: 'Nome',
      lastname: 'Sobrenome',
      partySize: 'Quantas pessoas há no seu grupo?',
      phoneNumber: 'Número de telefone',
      smsNotice: 'As atualizações por SMS sobre o seu status na fila serão enviadas para este número de telefone. Podem aplicar taxas pelo serviço.',
      reason: 'Qual é o propósito da sua visita?',
      otherReason: 'Se outro, por favor especifique',
      noOptions: "No options",
      privacy: {
        confirm: 'Selecione esta caixa para certificar que o número que você forneceu é o seu próprio número e que você concorda com a Política de Privacidade.',
        more: {
          title: '',
          what: '',
          details: {
            sl: '',
            balenciaga: '',
            gucci: "A Guccio Gucci S.p.A. ('Gucci', 'nós'), na sua qualidade de controlador de dados, pretende informar que processará os dados que nos forneceu com o objetivo de: (i) gerir a fila de espera na entrada dos pontos de venda; e (ii) reconhecê-lo, caso já tenha um perfil, antes de aceder ao ponto de venda, para otimizar o seu atendimento. O processamento dos seus dados será realizado por pessoal autorizado de acordo com a lei, apenas com o propósito de lhe fornecer o serviço solicitado. Os seus dados pessoais não serão transferidos para fora do país onde foram originalmente recolhidos ou da Área Econômica Europeia (AEE), divulgados ou de outra forma comunicados a terceiros. Pode exercer os seus direitos a qualquer momento, de acordo com a legislação de privacidade aplicável, enviando um e-mail para privacy@gucci.com. Informações mais detalhadas sobre os seus direitos estão disponíveis no seguinte link (<privacyLink>{{link}}</privacyLink>)",
            amq: '',
            brioni: '',
            bv: '',
          },
        },
      },
      signUp: 'Confirmar',
      countryCode: 'Código de país',
      errors: {
        required: 'Obrigatório',
        minLength: 'Deve ter {{{val number}} caracteres ou mais',
        minNumberOfGuests: 'Deve ser pelo menos {{val number}}',
        maxNumberOfGuests: 'Deve ter um máximo de {{val number}}',
        invalidCountryCode: 'Código de país inválido',
        invalidPhoneNumber: 'Número de telefone inválido',
        privacy: 'Você deve concordar com os termos',
      },
    },
  },
  shared: {
    loading: 'Carregando...',
  },
  status: {
    accepted: 'Você já foi aceito na loja.',
    canceled: {
      confirm: 'Você foi removido com sucesso da fila.',
      thanks: 'Será um prazer receber você novamente.',
    },
    registered: {
      description: {
        part1: 'Obrigado. {{firstname}}!',
        part2: 'Agora você',
        part3: 'na fila',
        part4: 'para {{storeName}}“',
      },
      next: 'Agora é a sua vez de entrar na loja! Por favor, faça o scan do Código QR na entrada da loja',
      cancellation: 'Não está mais interessado em visitar nossa loja? <cancelLink>Clique aqui para deixar a fila</cancelLink>.',
      register: {
        status: 'Cadastre-se para receber uma experiência personalizada na loja toda vez que você fizer compras conosco.',
        button: 'Cadastre meu perfil',
      },
      cancel: {
        title: 'Deixar fila',
        body: 'Se você sair da fila agora você vai perder seu lugar.',
        secondary: 'Ficar',
        primary: 'Sair',
      },
      bookAnAppointment: 'Evite a fila e <bookingLink>agende um horário</bookingLink>',
    },
  },
  errors: {
    global: 'Desculpe, algo deu errado.',
    queueDisabled: 'A fila para {{brand}} {{storeName}} está fechada.',
  },
  welcome: {
    language: 'Idioma',
    info: 'Seja bem-vindo! Há {{count}} pessoas na fila para {{storeName}}',
    signUp: 'Clique no botão abaixo para se inscrever na fila',
    action: 'Cadastre-se',
  },
};


export default data;
