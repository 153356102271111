import { Locale } from "../generated/graphql";

const APP_LOCALE_KEY = 'appLocale';

export const setLocaleToLocalStorage = (locale: Locale) => {
  return localStorage.setItem(APP_LOCALE_KEY, locale);
}

export const getLocaleFromLocalStorage = () => {
  const value: string | null = localStorage.getItem(APP_LOCALE_KEY);

  if (value === null) {
    return undefined;
  }

  return value as Locale;
}
