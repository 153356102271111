import i18next from 'i18next';
import React, { useContext, useState } from 'react';
import { getLocaleFromLocalStorage, setLocaleToLocalStorage } from '../../utils/localStorage';
import { Locale } from '../../generated/graphql';
import { getLanguageFromLocale, getLocaleFromLanguage } from '../../utils/languages';


/**
 * QmProviderProps interface
 */
interface QmProviderProps {
  children: React.ReactNode;
}

/**
 * QmContextProps interface
 */
interface QmContextProps {
  isLanguagePickerOpen: boolean;
  availableLocales: Locale[];
  setLanguage: ((language: string) => void);
  setAvailableLocales: ((locales: Locale[]) => void);
  getCurrentLocale: (() => Locale);
  initLocales: ((brandsLocales: Locale[], locationLocale: Locale, userLocale?: Locale) => void);
  showLanguagePicker: (() => void);
  hideLanguagePicker: (() => void);
}

/**
 * QmStateProps interface
 */
interface QmStateProps {
  isLanguagePickerOpen: boolean;
  initialized: boolean;
  availableLocales: Locale[];
}

/*
 * Qm Context
 */
// tslint:disable-next-line: variable-name
const QmContext: React.Context<QmContextProps> = React.createContext<QmContextProps>({
  isLanguagePickerOpen: false,
  availableLocales: [],
  setLanguage: (_: string) => undefined,
  setAvailableLocales: (_: Locale[]) => undefined,
  getCurrentLocale: () => getLocaleFromLanguage(i18next.resolvedLanguage),
  initLocales: (_: Locale[], __: Locale, ___?: Locale) => undefined,
  showLanguagePicker: () => undefined,
  hideLanguagePicker: () => undefined,
});

/**
 * Qm Provider
 */
// tslint:disable-next-line: typedef variable-name
export const QmProvider = ({ children }: QmProviderProps): JSX.Element => {
  const defaultLocale: Locale = getLocaleFromLanguage(i18next.resolvedLanguage);

  const initialQmState: QmStateProps = {
    isLanguagePickerOpen: false,
    initialized: false,
    availableLocales: [defaultLocale],
  };

  const [qmState, setQmState] = useState<QmStateProps>(initialQmState);

  const showLanguagePicker: () => void = () => {
    setQmState({
      ...qmState,
      isLanguagePickerOpen: true,
    });
  };
  const hideLanguagePicker: () => void = () => {
    setQmState({
      ...qmState,
      isLanguagePickerOpen: false,
    });
  };

  const availableLocales: Locale[] = qmState.availableLocales ?? [defaultLocale];

  const getCurrentLocale: () => Locale = () => getLocaleFromLocalStorage() ?? defaultLocale;

  const setAvailableLocales: (languages: Locale[]) => void = (languages: Locale[]) => {
    setQmState({
      ...qmState,
      availableLocales: languages.length === 0 ? [defaultLocale] : languages,
      initialized: true,
    });
  };

  const setLanguage: (language: string) => void = (language: string) => {
    if (i18next.language !== language) {
      i18next.changeLanguage(language).then(() => {
        setLocaleToLocalStorage(getLocaleFromLanguage(language))
      })
        .catch(() => 'Unable to change language');
    }
    if (qmState.isLanguagePickerOpen) {
      hideLanguagePicker();
    }
  };

  const initLocales: (brandsLocales: Locale[], locationLocale: Locale, userLocale?: Locale) => void =
    (brandsLocales: Locale[], locationLocale: Locale, userLocale?: Locale) => {
      if (qmState.initialized) {
        return;
      }
      setAvailableLocales(brandsLocales);

      const localStorageLocale: Locale | undefined = getLocaleFromLocalStorage();

      const locale: Locale = getLocaleByPriority({ defaultLocale, brandsLocales, localStorageLocale, locationLocale, userLocale })
      // QA Debug - To Remove ALB-1374

      console.log({ locale, defaultLocale, brandsLocales, localStorageLocale, locationLocale })
      const language: string = getLanguageFromLocale(locale);

      setLanguage(language);
      setLocaleToLocalStorage(locale)
    };

  // qmState
  return (
    <QmContext.Provider
      value={{
        isLanguagePickerOpen: qmState.isLanguagePickerOpen,
        availableLocales,
        getCurrentLocale,
        initLocales,
        setLanguage,
        setAvailableLocales,
        showLanguagePicker,
        hideLanguagePicker
      }}
    >
      {children}
    </QmContext.Provider>
  );
};

// tslint:disable-next-line: typedef
const useQm = () => useContext(QmContext);


export const getLocaleByPriority = ({ defaultLocale, brandsLocales, localStorageLocale, locationLocale, userLocale }: {
  defaultLocale: Locale,
  brandsLocales: Locale[],
  locationLocale: Locale,
  localStorageLocale: Locale | undefined,
  userLocale: Locale | undefined,
}) => {

  let locale: Locale = defaultLocale; // Default Locale

  // if user localStorage Locale is part of the brand Locale list, we use it
  const hasValidLocalStorageLanguage = localStorageLocale !== undefined && brandsLocales.includes(localStorageLocale)
  if (hasValidLocalStorageLanguage) {
    locale = localStorageLocale;
  } else if (userLocale !== undefined && brandsLocales.includes(userLocale)) {
    locale = userLocale;
    // if browser language is part of the brand Locale list
  } else if (brandsLocales.map(String).includes(getLocaleFromLanguage(navigator.language))) {
    locale = getLocaleFromLanguage(navigator.language);
    // if location Locale is part of the brand Locale list, we use it
  } else if (brandsLocales.includes(locationLocale)) {
    locale = locationLocale;
  }

  return locale
}

export { QmProvider as default, useQm };
