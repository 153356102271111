import { ReportHandler } from 'web-vitals';

/**
 * reportWebVitals
 */
export const reportWebVitals: (onPerfEntry?: ReportHandler | undefined) => void =
  (onPerfEntry?: ReportHandler): void => {
    if (onPerfEntry !== undefined && onPerfEntry instanceof Function) {
      import('web-vitals')
        .then(({ getCLS, getFID, getFCP, getLCP, getTTFB }: any) => {
          getCLS(onPerfEntry);
          getFID(onPerfEntry);
          getFCP(onPerfEntry);
          getLCP(onPerfEntry);
          getTTFB(onPerfEntry);
        })
        .catch((e: Error) => {
          console.error('Error on reportWebVitals:', e);
        });
    }
  };
