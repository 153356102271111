import { TranslationType } from 'i18n/type';

const data: TranslationType = {
  language: 'Spanish',
  registration: {
    signUp: 'Registrarse en la cola',
    registerForm: {
      firstname: 'Nombre',
      lastname: 'Apellido',
      partySize: '¿Cuántas personas hay en su grupo?',
      phoneNumber: 'Número de teléfono',
      smsNotice: 'Los SMS con las actualizaciones sobre el estado de la cola se enviarán a este número de teléfono. Se aplicará la tarifa estándar.',
      reason: '¿Cuál es el motivo de su visita?',
      otherReason: 'Si es otro, especifíquelo',
      noOptions: "No options",
      privacy: {
        confirm: 'Marque esta casilla para aceptar la <privacyLink>Política de privacidad</privacyLink> y para confirmar que el número de teléfono que ha facilitado es el suyo propio.',
        more: {
          title: 'Información personal',
          what: '¿Cómo utilizamos sus datos personales?',
          details: {
            sl: 'YVES SAINT LAURENT SAS, como responsable \ndel tratamiento de sus datos, procesa sus datos personales de la siguiente forma: (i) gestionar las colas de entrada a las boutiques. (ii) si ya dispone de un perfil, reconocerle, antes de entrar al punto de venta para optimizar su recibimiento. Usted se beneficia de varios derechos respecto a sus datos personales. Si desea obtener información adicional sobre el tratamiento de sus datos personales (incluido como puede ejercer sus derechos), haga click aquí.  <privacyLink>{{link}}</privacyLink>.',
            balenciaga: '',
            gucci: "Guccio Gucci S.p.A. ['Gucci 'nosotros'), en su calidad de responsable de gestor de datos, tiene la intención de informarle que tratará los datos que usted nos facilitó con la finalidad de: (i) gestionar las colas en la entrada de los puntos de venta; y (ii) siempre que ya disponga de un perfil, reconocerle antes de acceder al punto de venta, para optimizar su recepción. El tratamiento de sus datos será realizado por personal autorizado según la ley, únicamente con el fin de prestarle el servicio solicitado. Sus datos personales no se transferirán fuera del país donde se recopilaron originalmente o del EEA, ni se divulgarán ni comunicarán de otro modo a terceros. Puede ejercer sus derechos en cualquier momento de acuerdo con su legislación de privacidad aplicable, enviando un correo electrónico a privacidad@gucci.com. Más información detallada sobre sus derechos está disponible en el siguiente enlace (<privacyLink>{{link}}</privacyLink>).",
            amq: '',
            brioni: '',
            bv: '',
          },
        },
      },
      countryCode: 'prefijo internacional',
      errors: {
        required: 'Obligatorio',
        minLength: 'Debe tener {{val, number}} caracteres o más',
        minNumberOfGuests: 'Debe tener como mínimo {{val, number}}',
        maxNumberOfGuests: 'Debe tener como máximo {{val, number}}',
        invalidCountryCode: 'Código de país no válido',
        invalidPhoneNumber: 'Número de teléfono no válido',
        privacy: 'Debe aceptar las condiciones',
      },
      signUp: 'Confirmar',
    },
  },
  shared: {
    loading: 'Cargando...',
  },
  status: {
    accepted: 'Ya se le había admitido en la tienda.',
    canceled: {
      confirm: 'Se le ha eliminado correctamente de la cola.',
      thanks: 'Nos alegrará verle la próxima vez.',
    },
    registered: {
      description:
      {
        part1: 'Gracias. Es el siguiente en la cola para {{firstname}}!',
        part2: '',
        part3: '',
        part4: '',
      },
      next: '¡Ahora es su turno para entrar a la tienda! Escanee el código QR en la puerta',
      register: {
        status: 'Regístrese para recibir una experiencia personalizada en la tienda cada vez que compre con nosotros.',
        button: 'Registrar mi perfil',
      },
      cancellation: '¿Ya no quiere visitar nuestra tienda? <cancelLink>Pinche aquí para salir de la cola</cancelLink>.',
      cancel: {
        title: 'Salir de la cola',
        body: 'Si sale de la cola, perderá su puesto.',
        secondary: 'Quedarse',
        primary: 'Salirse',
      },
      bookAnAppointment: 'Evita la espera y <bookingLink>haz tu reserva</bookingLink>',
    },

  },
  welcome: {
    language: 'Idioma',
    info: 'Bienvenido. Hay {{count}} personas en la cola para {{storeName}}',
    signUp: 'Pulse en «registrarse» para ponerse a la cola',
    action: 'Registrarse',
  },
  errors: {
    global: 'Lo sentimos, algo ha fallado.',
    queueDisabled: 'La cola para {{brand}} en {{storeName}} está cerrada',
  },
};

export default data;
