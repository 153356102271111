import { QueryResult } from '@apollo/client';

import { GetActionLinksQuery, useGetActionLinksQuery } from '../../generated/graphql';

/**
 * useGetActionLinks
 */
export function useGetActionLinks(locationId?: string, memberId?: string): QueryResult<GetActionLinksQuery> {
  return useGetActionLinksQuery({
    variables: {
      locationId,
      memberId,
    },
  });
}
