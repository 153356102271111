import { BrandCode } from '@kering/types-shared';
import React from 'react';

import { getBrandFromHostname } from '../../utils/brands';

import { Layout } from './layout';

/**
 * WithBrandProps
 */
export interface WithBrandProps {
  brandCode: BrandCode;
}

/**
 * PageContainer
 */
export function withBrand<T extends WithBrandProps = WithBrandProps>(
  // tslint:disable-next-line: variable-name
  WrappedComponent: React.ComponentType<T>,
  pageIdentifier: string,
  fullpage?: boolean,
): JSX.Element {
  const displayName: string =
    WrappedComponent.displayName ?? WrappedComponent.name ?? 'Component';

  // tslint:disable-next-line: variable-name
  const ComponentWithBrand: any = (props: Omit<T, keyof WithBrandProps>) => {
    const brandProps: WithBrandProps = {
      brandCode: getBrandFromHostname(window.location.hostname),
    };

    return (
      <Layout id={pageIdentifier} fullpage={fullpage} brandCode={brandProps.brandCode}>
        <WrappedComponent {...brandProps} {...(props as T)} />
      </Layout>
    );
  };

  ComponentWithBrand.displayName = `withBrand(${displayName})`;

  return ComponentWithBrand;
}
