// tslint:disable no-default-import
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationDE from './de/translation';
import translationEN from './en/translation';
import translationFR from './fr/translation';
import translationIT from './it/translation';
import translationSP from './sp/translation';
import translationKR from './kr/translation';
import translationJP from './jp/translation';
import translationCHSimplified from './ch-simplified/translation';
import translationCHTraditional from './ch-traditional/translation';
import translationTH from './th/translation';
import translationAR from './ar/translation';
import translationNL from './nl/translation';
import translationPT from './pt/translation';
import translationPTBR from './pt-br/translation';

/**
 * translation resources
 */
export const resources: Record<string, any> = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  de: {
    translation: translationDE,
  },
  it: {
    translation: translationIT,
  },
  es: {
    translation: translationSP,
  },
  ko: {
    translation: translationKR,
  },
  jp: {
    translation: translationJP,
  },
  'zh-CN': {
    translation: translationCHSimplified,
  },
  'zh-TW': {
    translation: translationCHTraditional,
  },
  th: {
    translation: translationTH,
  },
  ar: {
    translation: translationAR,
  },
  nl: {
    translation: translationNL,
  },
  pt: {
    translation: translationPT,
  },
  'pt-BR': {
    translation: translationPTBR,
  },
} as const;

/**
 * init
 */
export async function init(): Promise<void> {
  await i18next
    .use(initReactI18next)
    .init({
      fallbackLng: 'en-EN',
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      resources,
    });
}
