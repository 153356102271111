import { TranslationType } from 'i18n/type';

const data: TranslationType = {
  language: 'Portuguese',
  registration: {
    signUp: 'Inscrever-se para a fila de espera',
    registerForm: {
      firstname: 'Primeiro nome',
      lastname: 'Apelido',
      partySize: 'Quantas pessoas estão na sua festa?',
      phoneNumber: 'Número de telefone',
      smsNotice: 'As actualizações por SMS relativas ao seu estado de fila de espera serão enviadas para este número de telefone. Aplicam-se as tarifas normais.',
      reason: 'Qual é o objectivo da sua visita?',
      otherReason: 'Se for outro, por favor especifique',
      noOptions: "No options",
      privacy: {
        confirm: 'Seleccione essa caixa para certificar que o número que forneceu é o seu próprio número e que concorda com a Política de Privacidade <privacyLink>Privacy Policy</privacyLink>.',
        more: {
          title: '',
          what: '',
          details: {
            sl: '',
            balenciaga: '',
            gucci: "A Guccio Gucci S.p.A. ('Gucci', 'nós'), na sua qualidade de controlador de dados, pretende informar que processará os dados que nos forneceu com o objetivo de: (i) gerir a fila de espera na entrada dos pontos de venda; e (ii) reconhecê-lo, caso já tenha um perfil, antes de aceder ao ponto de venda, para otimizar o seu atendimento. O processamento dos seus dados será realizado por pessoal autorizado de acordo com a lei, apenas com o propósito de lhe fornecer o serviço solicitado. Os seus dados pessoais não serão transferidos para fora do país onde foram originalmente recolhidos ou da Área Econômica Europeia (AEE), divulgados ou de outra forma comunicados a terceiros. Pode exercer os seus direitos a qualquer momento, de acordo com a legislação de privacidade aplicável, enviando um e-mail para privacy@gucci.com. Informações mais detalhadas sobre os seus direitos estão disponíveis no seguinte link (<privacyLink>{{link}}</privacyLink>)",
            amq: '',
            brioni: '',
            bv: '',
          },
        },
      },
      signUp: 'Confirme',
      countryCode: 'Código do país',
      errors: {
        required: 'Obrigatório',
        minLength: 'Deve ter {{val number}} caracteres ou mais',
        minNumberOfGuests: 'Deve ser, pelo menos, {{val number}}',
        maxNumberOfGuests: 'Deve ter no máximo {{val number}}',
        invalidCountryCode: 'Código de país inválido',
        invalidPhoneNumber: 'Número de telefone inválido',
        privacy: 'Deve concordar com os termos',
      },
    },
  },
  shared: {
    loading: 'Carregando...',
  },
  status: {
    accepted: 'Já foi aceite na loja.',
    canceled: {
       confirm: 'Foi retirado com sucesso da fila da espera.',
      thanks: 'Teremos todo o prazer em vê-lo da próxima vez.',
    },
    registered: {
      description: {
        part1: 'Obrigado. {{firstname}}!',
        part2: 'Agora é',
        part3: 'o próximo',
        part4: 'na fila para {{storeName}}.',
      },
      next: 'É agora a sua vez de entrar na loja! Por favor, digitalize o Código QR na entrada da loja',
      cancellation: 'Já não está interessado em visitar a nossa loja? <cancelLink>Clique aqui para deixar a fila de espera</cancelLink>.',
      register: {
        status: 'Registe-se para receber uma experiência personalizada na loja sempre que fizer compras connosco.',
        button: 'Registar o meu perfil',
      },
      cancel: {
        title: 'Deixar fila de espera',
        body: 'Se deixar a fila de espera agora perderá o seu lugar.',
        secondary: 'Fique',
        primary: 'Sair',
      },
      bookAnAppointment: 'Esqueça a fila e <bookingLink>faça uma marcação</bookingLink>',
    },
  },
  errors: {
    global: 'Lamento que algo tenha corrido mal.',
    queueDisabled: 'A fila de espera para {{brand}} {{storeName} está fechada',
  },
  welcome: {
    language: 'Idioma',
    info: 'Bem-vindo, há {{count}} pessoas na fila de espera para {{storeName}}',
    signUp: 'Toque em inscrever-se abaixo para se juntar à fila de espera',
    action: 'Inscrever-se',
  },
};


export default data;
