import { createBrowserHistory, History } from 'history';
import i18next from 'i18next';
import React, { useState } from 'react';
import { Route, Router, Switch } from 'react-router-dom';

import { Registration } from '../Registration/Pages';
import { Status } from '../Status/Pages';
import { Welcome } from '../Welcome/Pages';

import { Error } from './error';

const customHistory: History<History.PoorMansUnknown> = createBrowserHistory();

/**
 * App
 */


export function App(): JSX.Element {
  const [isRTL, setisRTL] = useState(false)
  i18next.on('languageChanged', () => {
    if (i18next.dir() === 'rtl') {
      setisRTL(true)
    } else {
      setisRTL(false)
    }
  })

  return (
    <>
      {isRTL && <style type="text/css">
        {`
          body {
            direction: rtl;
          }
        `}
      </style>
      }
      <Router history={customHistory}>
        <Switch>
          <Route path='/m/:memberId/s' exact>
            <Status />
          </Route>
          <Route path='/l/:locationId' exact>
            <Welcome />
          </Route>
          <Route path='/l/:locationId/r' exact>
            <Registration />
          </Route>
          <Route>
            <Error />
          </Route>
        </Switch>
      </Router>
    </>
  );
}
