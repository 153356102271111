import { TranslationType } from 'i18n/type';

const data: TranslationType = {
  language: 'Italiano',
  registration: {
    signUp: 'Registrazione per il sistema di coda all\'ingresso ',
    registerForm: {
      firstname: 'Nome',
      lastname: 'Cognome',
      partySize: 'Numero di persone?',
      phoneNumber: 'Numero di telefono',
      smsNotice: 'Aggiornamenti sullo stato della coda saranno inviati a questo numero di telefono. Vengono applicate tariffe standard.',
      reason: 'Qual è la ragione della sua visita?',
      otherReason: 'Se altro, specifica',
      noOptions: "No options",
      privacy: {
        confirm: 'Selezioni questa casella per certificare che il numero fornito appartiene a lei e acconsente alla nostra <privacyLink>Privacy Policy</privacyLink>.',
        more: {
          title: 'Dati Personali',
          what: 'Come utilizziamo i suoi dati personali?',
          details: {
            sl: 'YVES SAINT LAURENT SAS processa i suoi dati personali ai fini di: (i) gestire la coda all\'ingresso dei punti vendita; e (ii) premesso che lei possieda già un profilo, riconoscerla all\'ingresso e accoglierla. Per maggiori informazioni in merito al trattamento dei dati personali clicchi al seguente link <privacyLink>{{link}}</privacyLink>.',
            balenciaga: '',
            gucci: "Guccio Gucci S.p.A. ['Gucci' ‘noi'), in qualità di titolare del trattamento, intende informarLa che tratterà i dati da Lei forniti al fine di: (i) gestire le code all'ingresso dei punti di vendita; e (ii) effettuare il riconoscimento, se già dispone di un profilo, prima di accedere al punto vendita, per ottimizzare la Sua accoglienza. Il trattamento dei dati sarà effettuato da personale autorizzato ai sensi della legge, al solo fine di fornirLe il servizio richiesto. I dati personali non saranno trasferiti al di fuori del Paese in cui sono stati originariamente raccolti o dello Spazio Economico Europeo, né saranno divulgati o altrimenti comunicati a terzi. Potrà esercitare in qualsiasi momento i diritti previsti dalla normativa privacy applicabile, inviando un'e-mail a privacy@gucci.com. Ulteriori informazioni dettagliate sui Suoi diritti sono disponibili al seguente link (<privacyLink>{{link}}</privacyLink>).",
            amq: '',
            brioni: '',
            bv: '',
          },
        },
      },
      signUp: 'Conferma',
      countryCode: 'prefisso internazionale',
      errors: {
        required: 'Richiesto',
        minLength: 'Deve contenere minimo {{val, number}} caratteri',
        minNumberOfGuests: 'Deve essere minimo {{val, number}}',
        maxNumberOfGuests: 'Deve essere massimo {{val, number}}',
        invalidCountryCode: 'Codice paese non valido',
        invalidPhoneNumber: 'Numero di telefono non valido',
        privacy: 'Necessario accettare i Termini',
      },
    },
  },
  shared: {
    loading: 'Caricamento...',
  },
  status: {
    accepted: 'Accettazione in negozio avvenuta con successo.',
    canceled: {
      confirm: 'Rimozione dalla coda avvenuta con successo.',
      thanks: 'Saremo lieti di accoglierla nuovamente alla prossima occasione.',
    },
    registered: {
      description: {
        part1: 'Grazie, {{firstname}}!',
        part2: 'lei è ora il',
        part3: 'primo cliente',
        part4: 'in attesa "{{storeName}}"',
      },
      next: 'E\' il suo turno per entrare in negozio! Scansioni il QR code all\'ingresso',
      cancellation: '<cancelLink>Clicchi qui per abbandonare la coda</cancelLink>.',
      register: {
        status: 'Inserisca i suoi dati per ricevere un\'esperienza personalizzata ad ogni visita in negozio.',
        button: 'Registra il mio profilo',
      },
      cancel: {
        title: 'Lasciare la coda',
        body: 'Se abbandona la coda ora perderà il suo posto.',
        secondary: 'Rimani',
        primary: 'Abbandona',
      },
      bookAnAppointment: 'Salta la fila e <bookingLink>prenota un appuntamento</bookinglink>',
    },
  },
  errors: {
    global: 'Ops qualcosa non ha funzionato.',
    queueDisabled: 'La coda per {{brand}} {{storeName}} è ora chiusa',
  },
  welcome: {
    language: 'Lingua:',
    info: 'Benvenuto, ci sono {{count}} persone in coda per {{storeName}}',
    signUp: 'Clicchi qui o effettui l\'accesso per mettersi in coda',
    action: 'Effettuare l\'accesso',
  },
};

export default data;
